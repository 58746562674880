import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MainContentComponent} from './components/main-content/main-content.component';
import {TopPlaylistsComponent} from './components/top-playlists/top-playlists.component';

const routes: Routes = [
  {path: '', component: MainContentComponent},  // Default route
  {path: 'similar/:artistName', component: MainContentComponent},  // Default route
  {path: 'artist/:artistName', component: MainContentComponent},  // Default route

  // Dynamic playlists route
  {path: 'playlist/:artist', component: MainContentComponent},
  {path: 'playlist/top-playlists', component: TopPlaylistsComponent},
  {path: 'signup', component: MainContentComponent},
  {path: 'explorer-signup', component: MainContentComponent},
  {path: 'affiliate-program', component: MainContentComponent},

  // Lazy-loaded modules
  {
    path: 'navbar',
    loadChildren: () => import('./components/nav-bar/nav-bar.module').then(m => m.NavBarModule),
  },
  {
    path: 'pipes',
    loadChildren: () => import('./pipes/pipe.module').then(m => m.PipeModule)
  },
  {
    path: 'directives',
    loadChildren: () => import('./directives/directive.module').then(m => m.DirectiveModule)
  },
  {
    path: 'shared',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule)
  },

  // Redirection rule for trailing slashes
  {
    path: ':path/',
    pathMatch: 'full',
    redirectTo: '/:path'
  },

  // (Optional) Catch-all wildcard route for 404 handling or other purposes
  // { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
