import {ChangeDetectionStrategy, Component, effect, OnInit, signal} from '@angular/core';
import {SpotifyPlaylistService} from '../../services/spotify/spotify-playlist.service';
import {GlobalStateService} from '../../services/global-state.service';
import {Observable, Subject} from 'rxjs';
import {FinalSong} from '../../interfaces/final-song';
import {environment} from '../../../environments/environment.prod';
import {FormControl, Validators} from '@angular/forms';
import {copyToClipboard} from '../../helper/clipboard';
import {YoutubePlaylistService} from '../../services/youtube/youtube-playlist.service';
import {PlaylistReturnObject, SongsNotFound} from '../../interfaces/playlist-return-object';
import {UserStateService} from '../../services/user-state.service';
import {ToastService} from '../../services/toast.service';
import {ModalService} from '../../services/modal.service';
import {ModalType} from '../../interfaces/modal-types.enum';
import {HttpReqService} from '../../services/http-req.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'mypart-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex flex-col flex-1 overflow-hidden'
  },
  standalone: false
})
export class PlaylistComponent implements OnInit {
  loading$ = new Subject<boolean>();
  listTabSelected = signal(true);
  youtube_id = signal<string | undefined>(undefined);
  playlistId: string | undefined;
  playlistSongs = this.globalStateService.playlistSongs;
  spotifyPlaylistSongsArray: FinalSong[] = [];  // this is your array representation
  playlistName = new FormControl('', Validators.required);
  songsNotFound = signal<SongsNotFound[] | undefined>(undefined);
  isUserWhiteLabel = this.userStateService.isUserWhiteLabel;
  showRemovePopupSignal = signal(false);
  chosenSupplier = signal<'spotify' | 'youtube' | 'apple' | undefined>(undefined);
  playlistUrl = signal<string | null>(null);

  constructor(private spotifyPlaylistService: SpotifyPlaylistService,
              private youtubePlaylistService: YoutubePlaylistService,
              private globalStateService: GlobalStateService,
              private userStateService: UserStateService,
              private modalService: ModalService,
              private toast: ToastService,
              private httpReqService: HttpReqService,) {
    effect(() => {
      const playlistSongs = this.playlistSongs();
      this.listTabSelected.set(true);
      this.playlistName.reset();

      // Convert Set to array on component initialization
      this.spotifyPlaylistSongsArray = Array.from(playlistSongs);
    });
  }

  ngOnInit() {
  }

  getButtonColor(supplier: string): string {
    switch (supplier) {
      case 'spotify':
        return '#1fdf64';
      case 'youtube':
        return '#ff0000';
      case 'apple':
        return '#1e1e1e';
      default:
        return '#ffffff'; // default color if needed
    }
  }

  copyToClipboard(url: string) {
    copyToClipboard(this.toast, url);
  }

  emailPlaylist(url: string) {
    const subject = 'Check out my playlist!';
    const body = `Hey! I created a new playlist. Check it out here: ${url}`;
    window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  removeFromPlaylist(song: FinalSong) {
    this.globalStateService.addRemoveSongFromPlaylist(song);
    this.globalStateService.removeSongAddedToPlaylist(song);
    if (this.playlistSongs().size === 0) {
      this.hidePlaylist();
    }
  }

  removeAllSongs() {
    const songs = this.playlistSongs();
    for (const song of songs) {
      this.removeFromPlaylist(song);
    }
  }

  onDrop(event: CdkDragDrop<FinalSong[]>) {
    if (event.previousIndex === event.currentIndex) return;

    moveItemInArray(this.spotifyPlaylistSongsArray, event.previousIndex, event.currentIndex);
    requestAnimationFrame(() => {
      this.playlistSongs.set(new Set(this.spotifyPlaylistSongsArray));
    });
  }

  onSubmit() {
    if (this.playlistName.valid) {
      if (this.userStateService.user()) {
        this.exportPlaylist();
      } else {
        this.modalService.open(ModalType.LOGIN_REGISTER, {
          inputs: {
            loginPopupTitle: 'Sign Up for FREE to Export Your Playlist to Spotify',
            onlyExplorer: true
          }
        });
      }
    } else {
      this.playlistName.markAsTouched();
    }
  }

  showPlaylistName(supplier: 'spotify' | 'youtube' | 'apple') {
    this.chosenSupplier.set(supplier);
  }

  exportToCsv() {
    const songs = Array.from(this.playlistSongs());
    const notFoundSongs = this.songsNotFound();

    const finalList = songs.map(song => {
      const url = song.youtube_id ? `https://www.youtube.com/watch?v=${song.youtube_id}` : '';

      const baseSong = {
        artist: song.artist,
        title: song.title,
        url: url // Set the URL based on the youtube_id
      };

      if (notFoundSongs) {
        const match = notFoundSongs.some(notFound => notFound.artist === song.artist);
        return {...baseSong, notFoundOnSpotify: match || false};
      }

      return baseSong;
    });

    const headers = ['artist', 'title', 'url'];
    if (notFoundSongs) {
      headers.push('notFoundOnSpotify');
    }

    Promise.resolve(this.convertToCSV(finalList, headers))
      .then((csvData: string) => {
        const blob = new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement('a');

        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', 'FinalListExport.csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Show a popup message
          this.toast.success('Your file has been downloaded. You can find it in your default download location.');
        }
      })
      .catch(error => {
        this.toast.error('Error exporting to CSV: ' + error);
      });
  }

  showRemovePopup() {
    this.showRemovePopupSignal.set(true);
  }

  hidePlaylist() {
    this.globalStateService.showPlaylist.set(false);
  }

  playSong(song: FinalSong) {
    // this.iFrameAudioPlayerStateService.playSongFromPlaylist(song);
    // this.youtube_id.set(song.youtube_id);
    // this.iframeSrc =
    //   this.sanitizer.bypassSecurityTrustResourceUrl(
    //     `https://www.youtube.com/embed/${this.youtube_id()}?enablejsapi=1`);
  }

  private exportToApple() {

  }

  private async exportPlaylist() {
    this.loading$.next(true);
    const playlistName = `${environment.defaultPlaylistName} | ${this.getDate()} | ${this.playlistName.value!}`;
    const supplier = this.chosenSupplier();

    let exportObservable: Observable<PlaylistReturnObject>;
    switch (supplier) {
      case 'youtube':
        exportObservable = this.youtubePlaylistService.createYoutubePlaylist(
          this.globalStateService.getSongsForYoutube(), playlistName, this.playlistId);
        break;
      case 'apple':
        break;
      case 'spotify':
        exportObservable = this.spotifyPlaylistService.createSpotifyPlaylist(
          this.globalStateService.getSongsForSpotify(), playlistName, this.playlistId);
        break;
    }

    exportObservable!.subscribe({
      next: (result) => {
        if (result.error) {
          this.toast.error(`Could not create playlist. ${environment.toastSupportMessage}`);
        } else {
          this.playlistId = result.playlistId;
          this.playlistUrl.set(result.link);

          this.httpReqService.sendEventToServer('SH_PLAYLIST_SPOTIFY_EXPORT', {
            'email': this.userStateService.user()!.email,
            'url': result.link,
            'playlistId': result.playlistId
          });
        }
        this.songsNotFound.set(result.songsNotFound);
      },
      error: (err) => {
        this.toast.error(`Could not create playlist. ${environment.toastSupportMessage}`);
      },
      complete: () => this.loading$.next(false)
    });
  }

  private getDate() {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11
    const day = String(currentDate.getDate()).padStart(2, '0');
    const year = String(currentDate.getFullYear()).slice(-2); // Get last two digits of the year

    return `${month}/${day}/${year}`;
  }

  private convertToCSV(objArray: any[], headers: string[]): string {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    let str = '';
    const headerRow = headers.map(header => `"${header}"`).join(','); // Add quotes to headers
    str += headerRow + '\r\n';

    array.forEach((item: any) => {
      let line = '';
      headers.forEach((header, index) => {
        if (line !== '') line += ',';

        let value = item[header] !== undefined ? String(item[header]) : '';
        // Escape double quotes and wrap value in quotes
        value = `"${value.replace(/"/g, '""')}"`;

        line += value;
      });
      str += line + '\r\n';
    });

    return str;
  }
}
