import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  EnvironmentInjector,
  inject,
  Inject,
  Renderer2,
  runInInjectionContext,
  signal,
  ViewChild
} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Modal} from 'flowbite';
import {GlobalStateService} from '../../services/global-state.service';
import {ModalOptions} from 'flowbite/lib/esm/components/modal/types';
import {HttpReqService} from '../../services/http-req.service';
import {environment} from '../../../environments/environment.prod';
import {CuratedPlaylist} from '../../interfaces/curated-playlist';
import {DOCUMENT} from '@angular/common';
import {ToastService} from '../../services/toast.service';
import {NavBarComponent} from '../nav-bar/nav-bar.component';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-top-playlists',
  templateUrl: './top-playlists.component.html',
  styleUrl: './top-playlists.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class TopPlaylistsComponent implements AfterViewInit {
  @ViewChild('curatedPlaylistsPage', {static: false, read: ElementRef}) curatedPlaylistsPage: ElementRef | undefined;
  @ViewChild(NavBarComponent) navBarComponent!: NavBarComponent;

  isLoading = signal(false);
  curatedPlaylists = this.globalStateService.curatedPlaylists;
  showBigSearchHomePage = this.globalStateService.showBigSearchHomePage;
  appTheme = this.globalStateService.appTheme;
  artist: string | null = 'nothing';
  private modal: Modal | undefined;
  private readonly environmentInjector = inject(EnvironmentInjector); // ✅ Get injector

  constructor(private globalStateService: GlobalStateService,
              private httpReqService: HttpReqService,
              private renderer: Renderer2,
              private toast: ToastService,
              @Inject(DOCUMENT) private document: Document) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const options: ModalOptions = {
        backdrop: 'dynamic',
        closable: false,
        placement: 'top-center',
        backdropClasses: 'empty'
      };
      if (this.curatedPlaylistsPage?.nativeElement) {
        this.modal = new Modal(this.curatedPlaylistsPage.nativeElement, options);
      }
      runInInjectionContext(this.environmentInjector, () => {
        effect(
          () => {
            const show = this.globalStateService.showCuratedPlaylists(); // Signal

            if (show) {
              if (this.globalStateService.errorRetrievingPlaylists) {
                this.toast.warning(`Could not retrieve playlists. ${environment.toastSupportMessage}`);
              } else {
                this.isLoading.set(true); // ✅ Writing to signals inside effect

                const currentPlaylists = this.curatedPlaylists(); // Signal
                if (currentPlaylists) {
                  this.generateStructuredDataForCollection(currentPlaylists);
                }

                this.showModal();
              }
            }
          }
        );
      });
    }, 0); // Delays the modal initialization to ensure everything is in place
  }

  showModal() {
    this.modal?.show();
    this.isLoading.set(false);
  }

  hideModal() {
    this.modal?.destroyAndRemoveInstance();
    this.modal?.hide();
    this.modal = undefined;
    this.globalStateService.showCuratedPlaylists.set(false);
    // this.router.navigate([''],).then();
    window.history.pushState({}, 'Songhunt', this.httpReqService.trimUrlForPopups(window.location.href, 'playlist/'));
    if (location.pathname === '/') {
      this.globalStateService.setShowBigSearchHomePage(true, 'TopPlaylists - hideModal');
    }
  }

  private generateStructuredDataForCollection(playlists: CuratedPlaylist[]) {
    const itemListElement = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      'itemListElement': playlists.map((playlist, index) => ({
        '@type': 'ListItem',
        'position': index + 1,
        'item': {
          '@type': 'MusicPlaylist',
          'name': playlist.name,
          'description': playlist.description,
          'image': playlist.imageUrl,
          'url': playlist.songhuntLink || playlist.spotifyLink
        }
      }))
    };

    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'application/ld+json');
    this.renderer.setValue(script, JSON.stringify(itemListElement));
    this.renderer.appendChild(this.document.head, script);
  }
}
