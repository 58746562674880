<ngx-loading-bar [includeSpinner]="false" color="#C32727"></ngx-loading-bar>
<mypart-nav-bar #navBarComponent></mypart-nav-bar>
<div [style.padding-top.px]="marginTop()" class="main-theme pb-4">
  <router-outlet></router-outlet>
</div>

@if (showPlaylist()) {
  <div [@slideInOut]="showPlaylist()"
       class="fixed right-0 top-0 h-full w-full sm:w-96 z-50 transition-all rounded-lg shadow-2xl light-gradient dark:dark-gradient">
    <mypart-playlist></mypart-playlist>
  </div>
  <div class="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40" (click)="showPlaylist.set(false)"></div>
}
@if (showCuratedPlaylists()) {
  <mypart-top-playlists></mypart-top-playlists>pa
}
@if (showAdminPanel()) {
  <mypart-admin-panel></mypart-admin-panel>
}

@if (showProfilePage()) {
  <mypart-profile></mypart-profile>
}

<mypart-modal-container></mypart-modal-container>

<mypart-toast></mypart-toast>

<!--<div class="main">-->
<!--  <div class="initial-snow">-->
<!--    @for (snowFlake of snowArray; track snowFlake) {-->
<!--      <div class="snow">&#10052;</div>-->
<!--    }-->
<!--  </div>-->
<!--</div>-->
