<section class="flex h-full">
  <section [ngClass]="{'w-[calc(100%-60px)]': !isMaxTabletDevice()}" class="w-full">
    <section
        [ngClass]="{'w-full':showNoMoreResults() && !showBigSearchHomePage() && !showBigSearchHomePageIsUndefined()}"
        class="h-full overflow-y-auto overflow-x-hidden" id="results-scroll-container">
      <div #scrollTarget class="p-2 mt-2 sm:mt-0 md:p-4 w-full h-full grid gap-4 grid-cols-fit-sm sm:grid-cols-fit-lg"
           id="scrollTarget">
        <!--  Show Skeleton-->
        @if (!showBigSearchHomePage() && !showCuratedPlaylists() && !showNoMoreResults() && (songs()).length === 0) {
          @for (item of skeletonArray; track item) {
            <mypart-result-skeleton class="max-w-[400px]" [@pixelateInOut]="true"></mypart-result-skeleton>
          }
        }
        @if (!showBigSearchHomePage() && songs()!.length > 0) {
          @for (song of songs(); track song.id) {
            @if (song.showEmptyLine) {
              <div class="col-span-full flex items-center border-gray-300 my-4">
                <span class="flex-grow border-t border-gray-300"></span>
                <span class="mx-4 text-gray-500">Similar Songs</span>
                <span class="flex-grow border-t border-gray-300"></span>
              </div>
            } @else {
              <mypart-result-item
                  [shouldPrioritize]="$index < 25"
                  [isFirst]="$first"
                  [song]="song"
                  [songPositionInList]="$index"
                  class="pop-animate max-w-[400px]"
                  [attr.data-result-item]="song.youtube_id"
                  isInView>
              </mypart-result-item>
            }
          }
        }
        @if (showNoMoreResults() && !showBigSearchHomePage()) {
          <div class="h-full w-[100vw] flex justify-center items-end p-4 z-10">
            <div
                class="flex items-center p-6 rounded-xl bg-mypart_red-500/10 dark:bg-mypart_red-300/20 backdrop-blur-sm mb-4 shadow-lg max-w-[90vw] border border-mypart_red-300/30 dark:border-mypart_red-300/50"
                role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-mypart_red-500 dark:text-mypart_red-300"
                   fill="currentColor"
                   viewBox="0 0 20 20"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <div [innerHTML]="getSanitizedMessage()"
                   class="ml-4 text-base lg:text-lg font-medium text-center lg:text-left text-gray-800 dark:text-gray-100">
              </div>
            </div>
          </div>
        }
      </div>

      <mypart-player></mypart-player>
    </section>
  </section>

  <!-- Playlist bar -->
  @if (!showBigSearchHomePage() && !showBigSearchHomePageIsUndefined()) {
    @if (isMaxTabletDevice()) {
      <section [ngClass]="filtersVisible() ? 'top-[300px] ' : 'top-[240px] '"
               class="z-50 w-[40px] absolute right-0 h-[120px] transition-all">
        <div [@slideInOut]="showPlaylist()"
             class="mobile-playlist-bar main-theme" (mousedown)="showPlaylist.set(true)"
             (click)="showPlaylist.set(true)">
          <figure class="rotate-90 w-6 h-5 relative scale-effect">
            <img [attr.alt]="'The image shows a red icon with three horizontal bars stacked vertically on the left ' +
               'side and a downward-pointing arrow on the right side. Each element is outlined in white, making ' +
                'them stand out against the black background. The three bars represent a list, and the downward ' +
                 'arrow indicates an action related to viewing or expanding this list, such as opening or viewing ' +
                  'a playlist. The design is clean and modern, clearly communicating its function.'"
                 fill="true"
                 ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/mobile_playlist.png"
                 priority>
          </figure>
        </div>
      </section>
    } @else {
      <section
          class="w-[56px] mx-[2px] mt-[4px] border border-gray-300 dark:border-gray-700 h-[calc(100%-8px)] z-50 shadow-2xl">
        @if (!(showPlaylist())) {
          <div [@slideInOut]="showPlaylist()"
               class="main-theme !bg-transparent flex justify-center items-center cursor-pointer text-gray-700 dark:text-gray-400
            hover:text-mypart_red-500 dark:hover:text-mypart_red-500"
               (click)="showPlaylist.set(true)">
            <div class="rotate-90 relative flex flex-row items-end">
              <span class="text-lg new-underline-effect">Edit/Export Playlist</span>
              <figure class="w-6 h-5 ml-2 relative scale-effect">
                <img [attr.alt]="'The image shows a red icon with three horizontal bars stacked vertically on the left ' +
               'side and a downward-pointing arrow on the right side. Each element is outlined in white, making ' +
                'them stand out against the black background. The three bars represent a list, and the downward ' +
                 'arrow indicates an action related to viewing or expanding this list, such as opening or viewing ' +
                  'a playlist. The design is clean and modern, clearly communicating its function.'"
                     fill="true"
                     ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/mobile_playlist.png"
                     priority>
              </figure>
            </div>
          </div>
        }
      </section>
    }
  }
</section>
