import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment.prod';
import {debounceTime, filter, firstValueFrom, map, Subject} from 'rxjs';
import {GlobalStateService} from '../../services/global-state.service';
import param from 'jquery-param';

@Component({
  selector: 'mypart-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AdminPanelComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('tableContainer') tableContainer!: ElementRef;

  // UI State
  selectedTable: string | null = null;
  isEditing = false;
  isSidebarOpen = true;
  isLoading = false;
  loadingMessage = 'Loading...';
  notification: { type: 'success' | 'error'; message: string } | null = null;
  previewImage: string | null = null;
  previewTop = 0;
  previewLeft = 0;
  uploadProgress = 0;
  fullTextModal = false;
  fullTextContent = '';
  fullTextColumnName = '';

  // Image Handling
  currentImageUploadTarget: { row: any; column: string } | null = null;

  // Data State
  allTableNames: { data: string[] } | null = null;
  currentTableData: any[] = [];
  originalTableData: any[] = [];
  filteredData: any[] = [];
  searchQuery = '';
  sortColumn: string | null = null;
  sortDirection: 'asc' | 'desc' = 'asc';

  // Save Handling
  private saveSubject = new Subject<void>();

  private readonly httpPostOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}),
    withCredentials: true,
    observe: 'response' as 'response'
  };

  constructor(private httpClient: HttpClient,
              private globalStateService: GlobalStateService,
              private cdr: ChangeDetectorRef) {
    this.setupDebouncedSave();
  }

  ngOnInit() {
    this.getAllTables();
  }

  ngAfterViewInit() {
    this.resetScroll();
  }

  // UI Actions
  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.cdr.markForCheck();
  }

  formatTableName(tableName: string): string {
    return tableName.replace(/([A-Z])/g, ' $1').trim();
  }

  formatColumnName(column: string): string {
    return column.replace(/([A-Z])/g, ' $1').trim();
  }

  // Notifications
  showNotification(type: 'success' | 'error', message: string) {
    this.notification = {type, message};
    this.cdr.markForCheck();
    setTimeout(() => {
      this.notification = null;
      this.cdr.markForCheck();
    }, 3000);
  }

  // Table Operations
  getTableColumns(): string[] {
    if (!this.currentTableData || this.currentTableData.length === 0) {
      return [];
    }
    return Object.keys(this.currentTableData[0]);
  }

  isImageColumn(column: string): boolean {
    return column.toLowerCase().includes('image') ||
      column.toLowerCase().includes('cover');
  }

  isLongTextField(column: string): boolean {
    return column.toLowerCase().includes('description') ||
      column.toLowerCase().includes('filters') ||
      column.toLowerCase().includes('text') ||
      column.toLowerCase().includes('content');
  }

  isBooleanField(column: string, value: any): boolean {
    return typeof value === 'boolean' || value === 'true' || value === 'false';
  }

  getColumnClass(column: string): string {
    let classes = 'max-w-[400px] ';

    if (this.isImageColumn(column)) {
      classes += 'w-16';
    } else if (this.isLongTextField(column)) {
      classes += 'w-[400px]';
    } else {
      classes += 'w-[200px]';
    }

    return classes;
  }

  hasLongText(text: string): boolean {
    return !!(text && text.length > 50);
  }

  showFullText(text: string, columnName: string) {
    this.fullTextColumnName = columnName;
    this.fullTextContent = text;
    this.fullTextModal = true;
    this.cdr.markForCheck();
  }

  closeFullText() {
    this.fullTextModal = false;
    this.cdr.markForCheck();
  }

  showImagePreview(event: MouseEvent, imageUrl: string) {
    this.previewImage = imageUrl;
    const rect = (event.target as HTMLElement).getBoundingClientRect();

    // Show preview to the right of the image if possible
    const spaceOnRight = window.innerWidth - rect.right;
    if (spaceOnRight >= 320) {
      this.previewLeft = rect.right + 8;
      this.previewTop = rect.top;
    } else {
      this.previewLeft = rect.left - 320 - 8;
      this.previewTop = Math.min(rect.top, window.innerHeight - 320);
    }

    this.cdr.markForCheck();
  }

  hideImagePreview() {
    this.previewImage = null;
    this.cdr.markForCheck();
  }

  async uploadImage(file: File, row: any, column: string) {
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('table', this.selectedTable!);
      formData.append('column', column);

      this.isLoading = true;
      this.loadingMessage = 'Uploading image...';
      this.uploadProgress = 0;
      this.currentImageUploadTarget = {row, column};
      this.cdr.markForCheck();

      const response = await firstValueFrom(this.httpClient
        .post<any>(`${environment.baseUrlWithApi}admin/functions/upload-image`, formData, {
          withCredentials: true,
          observe: 'events',
          reportProgress: true
        })
        .pipe(
          map(event => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                if (event.total) {
                  this.uploadProgress = Math.round(100 * (event.loaded / event.total));
                  if (this.uploadProgress === 100) {
                    this.loadingMessage = 'Analyzing visual...';
                  }
                  this.cdr.markForCheck();
                }
                return null;
              case HttpEventType.Response:
                return event.body;
              default:
                return null;
            }
          }),
          filter(response => response !== null)
        ));

      if (response?.data) {
        const oldValue = row[column];
        row[column] = response.data;

        // If this image is currently being previewed, update the preview
        if (this.previewImage === oldValue) {
          this.previewImage = response.data;
        }

        // Mark row as edited and store original state if not already stored
        this.isEditing = true;
        if (!this.originalTableData.length) {
          this.originalTableData = JSON.parse(JSON.stringify(this.currentTableData));
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      this.showNotification('error', 'Failed to upload image');
    } finally {
      this.isLoading = false;
      this.uploadProgress = 0;
      this.currentImageUploadTarget = null;
      this.cdr.markForCheck();
    }
  }

  openImageUpload(row: any, column: string) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = (e: Event) => {
      const target = e.target as HTMLInputElement;
      if (target && target.files && target.files.length > 0) {
        this.uploadImage(target.files[0], row, column).then(() => {
          // Clear the input value after upload
          target.value = '';
        }).catch(error => {
          console.error('Error in upload promise:', error);
          this.isLoading = false;
          this.uploadProgress = 0;
          this.currentImageUploadTarget = null;
          this.cdr.markForCheck();
        });
      }
    };
    fileInput.click();
  }

  // Row Operations
  addNewRow() {
    // Create a new row with empty values for all columns
    const newRow: any = {};
    this.getTableColumns().forEach(column => {
      if (column !== 'id') {
        // Set boolean fields to false by default
        if (this.currentTableData.length > 0 && this.isBooleanField(column, this.currentTableData[0][column])) {
          newRow[column] = false;
        } else {
          newRow[column] = '';
        }
      }
    });

    // Add the new row to both currentTableData and filteredData
    this.currentTableData.unshift(newRow);
    this.filteredData.unshift(newRow);
    this.cdr.markForCheck();
  }

  deleteRow(index: number) {
    if (confirm('Are you sure you want to delete this row?')) {
      this.currentTableData.splice(index, 1);
      this.updateFilteredData();
    }
  }

  updateCellValue(row: any, column: string, event: Event) {
    row[column] = (event.target as HTMLInputElement | HTMLTextAreaElement).value;
  }

  async saveChanges() {
    try {
      this.isLoading = true;
      this.loadingMessage = 'Saving changes...';
      await this.performSave();
      this.isEditing = false;
      this.originalTableData = JSON.parse(JSON.stringify(this.currentTableData));
      this.cdr.markForCheck();
    } catch (error) {
      console.error('Error saving changes:', error);
    } finally {
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }

  // API Calls
  getAllTables() {
    this.isLoading = true;
    this.loadingMessage = 'Loading tables...';
    this.cdr.markForCheck();

    try {
      this.httpClient.get(
        `${environment.baseUrlWithApi}admin/functions/list-tables`,
        this.generateHttpGetOptions()
      ).subscribe({
        next: (tables: any) => {
          this.allTableNames = tables;
          if (tables?.data?.length > 0) {
            this.selectTable(tables.data[0]);
          }
        },
        error: (error) => {
          this.showNotification('error', 'Failed to load tables');
        },
        complete: () => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
    } catch (error) {
      console.error('Error in getAllTables:', error);
      this.showNotification('error', 'Failed to load tables');
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }

  getTable(table: string) {
    this.isLoading = true;
    this.loadingMessage = 'Fetching table data...';
    this.cdr.markForCheck();

    try {
      this.httpClient.get(
        `${environment.baseUrlWithApi}admin/functions/table`,
        this.generateHttpGetOptions({table})
      ).subscribe({
        next: (data: any) => {
          this.currentTableData = data?.data || [];
          this.originalTableData = JSON.parse(JSON.stringify(this.currentTableData));
          this.updateFilteredData();
          setTimeout(() => this.resetScroll(), 0);
        },
        error: (error) => {
          console.error('Error fetching table data:', error);
          this.showNotification('error', 'Failed to load table data');
        },
        complete: () => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }
      });
    } catch (error) {
      console.error('Error in getTable:', error);
      this.showNotification('error', 'Failed to load table data');
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }

  selectTable(tableName: string) {
    if (this.isEditing) {
      const confirm = window.confirm('You have unsaved changes. Do you want to continue?');
      if (!confirm) return;
    }

    this.selectedTable = tableName;
    this.isEditing = false;
    this.getTable(tableName);
  }

  startEditing() {
    this.isEditing = true;
    // Create a deep copy of the current data for reverting changes
    this.originalTableData = JSON.parse(JSON.stringify(this.currentTableData));
  }

  goHome() {
    if (this.hasChanges()) {
      const confirm = window.confirm('Are you sure you want to discard your changes?');
      if (!confirm) return;
    }

    this.globalStateService.showAdminPanel.set(false);
  }

  cancelEditing() {
    if (this.hasChanges()) {
      const confirm = window.confirm('Are you sure you want to discard your changes?');
      if (!confirm) return;
    }

    this.isEditing = false;
    this.currentTableData = JSON.parse(JSON.stringify(this.originalTableData));
    this.updateFilteredData();
    this.cdr.markForCheck();
  }

  hasChanges(): boolean {
    return JSON.stringify(this.currentTableData) !== JSON.stringify(this.originalTableData);
  }

  // Search and Sort
  onSearch(event: Event) {
    this.searchQuery = (event.target as HTMLInputElement).value.toLowerCase();
    this.updateFilteredData();
  }

  updateFilteredData() {
    this.filteredData = this.currentTableData.filter(row => {
      return Object.values(row).some(value => {
        if (value === null || value === undefined) return false;
        return value.toString().toLowerCase().includes(this.searchQuery);
      });
    });

    if (this.sortColumn) {
      this.sortData(this.sortColumn, this.sortDirection);
    }

    this.cdr.markForCheck();
  }

  sortData(column: string, direction?: 'asc' | 'desc') {
    if (!direction) {
      direction = this.sortColumn === column && this.sortDirection === 'asc' ? 'desc' : 'asc';
    }

    this.sortColumn = column;
    this.sortDirection = direction;

    this.filteredData.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue === bValue) return 0;
      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      const comparison = aValue.toString().localeCompare(bValue.toString(), undefined, {numeric: true});
      return direction === 'asc' ? comparison : -comparison;
    });

    this.cdr.markForCheck();
  }

  getSortIcon(column: string): string {
    if (this.sortColumn !== column) return 'none';
    return this.sortDirection === 'asc' ? 'asc' : 'desc';
  }

  private async performSave() {
    if (!this.selectedTable || !this.currentTableData) return;

    try {
      // Find changed rows by comparing with original data
      const changedRows = this.currentTableData.filter((row, index) => {
        // For new rows, include them
        if (index >= this.originalTableData.length) return true;

        // For existing rows, compare each field
        const originalRow = this.originalTableData[index];
        return Object.keys(row).some(key => row[key] !== originalRow[key]);
      });

      // Prepare changed data, only sending changed fields
      const changedData = changedRows.map(row => {
        const originalRow = this.originalTableData.find(orig => orig.id === row.id);
        if (!originalRow) {
          // This is a new row, send all fields except id
          const {id, ...newRow} = row;
          return newRow;
        }

        // This is an existing row, only send changed fields and id
        const changes: any = {id: row.id};
        Object.keys(row).forEach(key => {
          if (row[key] !== originalRow[key]) {
            changes[key] = row[key];
          }
        });
        return changes;
      });

      if (changedData.length === 0) return;

      const params = param({
        table: this.selectedTable,
        data: JSON.stringify(changedData)
      });

      await firstValueFrom(this.httpClient.post(
        `${environment.baseUrlWithApi}admin/functions/update-table`, params, this.httpPostOptions
      ));

      this.showNotification('success', 'Changes saved successfully');
    } catch (error) {
      console.error('Error saving changes:', error);
      this.showNotification('error', 'Failed to save changes');
    }
  }

  private setupDebouncedSave() {
    this.saveSubject.pipe(
      debounceTime(1000)
    ).subscribe(() => {
      this.performSave().then();
    });
  }

  private generateHttpGetOptions(params?: HttpParams | { [param: string]: string | string[]; }): any {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      withCredentials: true,
      params
    };
  }

  private resetScroll() {
    if (this.tableContainer?.nativeElement) {
      this.tableContainer.nativeElement.scrollTop = 0;
      this.tableContainer.nativeElement.scrollLeft = 0;
    }
  }
}
