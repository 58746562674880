<div>
  <div [ngClass]="{'blur-sm': loading$ | async}"
       class="relative w-full h-full mx-auto">
    <!-- Modal content -->
    <div class="relative">
      <!--Close button-->
      <button (click)="hidePlaylist()"
              class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 z-50
          hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center
          dark:hover:bg-gray-600 dark:hover:text-white" type="button">
        <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"/>
        </svg>
        <span class="sr-only">Close modal</span>
      </button>

      <div class="px-4 py-6 relative min-h-[100vh] grid grid-rows-[auto_1fr_auto]">
        <!--Tabs-->
        <div #tabs [ngClass]="{'blur-sm': showRemovePopupSignal()}"
             class="w-full flex mb-8 text-xl font-medium text-center text-gray-500 border-b
            border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <button (click)="listTabSelected.set(true)"
                  [ngClass]="{'text-[#c42727] border-[#c42727] dark:text-[#c42727] dark:border-[#c42727]': listTabSelected(),
                             'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': !listTabSelected()}"
                  class="w-full mr-2 p-4 border-b-2 border-transparent rounded-t-lg"
                  type="button">
            Playlist
          </button>
          @if (playlistSongs().size > 0) {
            <button (click)="listTabSelected.set(false)"
                    [ngClass]="{'text-[#c42727] border-[#c42727] dark:text-[#c42727] dark:border-[#c42727]': !listTabSelected(),
                               'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300': listTabSelected()}"
                    class="w-full ml-2 p-4 border-b-2 border-transparent rounded-t-lg"
                    type="button">
              Export
            </button>
          }
        </div>

        <!--List-->
        @if (listTabSelected()) {
          <div cdkDropList
               class="relative flex-1 -mx-4 overflow-y-auto h-[calc(100dvh-200px)]"
               (cdkDropListDropped)="onDrop($event)"
               [cdkDropListAutoScrollStep]="50">
            <ul class="divide-y divide-zinc-200 dark:divide-zinc-600 px-4 will-change-transform" role="list">
              @for (song of spotifyPlaylistSongsArray; track song.id; let i = $index) {
                <li cdkDrag cdkDragLockAxis="y"
                    class="group hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-transform duration-150 ease-out
                           px-4 py-3 relative will-change-transform">
                  <div class="flex justify-between items-center" cdkDragHandle>
                    <span class="w-[8%] flex items-center text-gray-400 dark:text-gray-500 font-medium">
                      {{ i + 1 }}
                    </span>
                    <div class="w-[82%] flex min-w-0 gap-x-4 items-center cursor-grab active:cursor-grabbing">
                      <div class="relative">
                        <img imageLoader [ngSrc]="song.imageUrl" priority
                             [alt]="'Cover image for the song - ' + song.title + ', By - ' + song.artist"
                             class="w-12 h-12 rounded-md object-cover shadow-sm
                                    transition-transform duration-200 group-hover:scale-105"
                             height="48" width="48">
                        <div class="absolute inset-0 bg-black/20 group-hover:bg-black/0 rounded-md
                                  transition-all duration-200 ease-in-out"></div>
                      </div>
                      <div class="min-w-0 flex-auto">
                        <div class="flex items-center gap-2">
                          <span class="text-sm font-medium text-gray-900 dark:text-white truncate group-hover:text-[#c42727]
                                     transition-colors duration-200" isEllipsis>
                            {{ song.title }}
                          </span>
                          <svg
                              class="w-4 h-4 text-[#c42727] opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                              fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
                          </svg>
                        </div>
                        <span class="text-xs text-gray-500 dark:text-gray-400 truncate block
                                   group-hover:text-gray-600 dark:group-hover:text-gray-300
                                   transition-colors duration-200" isEllipsis>
                          {{ song.artist }}
                        </span>
                      </div>
                    </div>
                    <div class="w-[10%] flex items-center justify-end gap-2">
                      @if (songsNotFound()) {
                        <svg (click)="tooltip.show()" #tooltip="tippy"
                             tp="Could not find song on spotify" tpTrigger="manual"
                             class="w-5 h-5 cursor-pointer text-[#c42727] hidden
                                    hover:scale-110 transition-transform duration-200"
                             [ngClass]="{'!block': song | notFoundOnExport : songsNotFound()!}"
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                      }
                      <button (click)="removeFromPlaylist(song)"
                              class="group-hover:opacity-100 opacity-0 transition-all duration-200
                                     hover:text-[#c42727] text-gray-400 dark:text-gray-500
                                     focus:outline-none focus:ring-2 focus:ring-[#c42727]/20 rounded-full p-1"
                              title="Remove song from the playlist">
                        <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="absolute bottom-0 left-0 w-full h-0.5 bg-[#c42727]/10 scale-x-0 group-hover:scale-x-100
                             transition-transform duration-300 ease-out"></div>
                </li>
              } @empty {
                <div class="flex flex-col items-center justify-center py-12 px-4">
                  <div class="w-24 h-24 mb-6 relative">
                    <div class="absolute inset-0 bg-[#c42727]/10 dark:bg-[#c42727]/20 rounded-full animate-pulse"></div>
                    <svg class="w-24 h-24 text-[#c42727] dark:text-[#c42727]/90 relative" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                            d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"/>
                    </svg>
                  </div>
                  <h3 class="text-xl font-semibold text-gray-900 dark:text-white mb-2 text-center">
                    Your Playlist is Empty
                  </h3>
                  <p class="text-gray-600 dark:text-gray-300 text-center mb-6">
                    Start building your perfect playlist! Search for your favorite songs and click the "+" button to add
                    them.
                  </p>
                  <div class="flex flex-col items-center gap-3 text-sm text-gray-500 dark:text-gray-400">
                    <div class="flex items-center gap-2">
                      <svg class="w-5 h-5 text-[#c42727]/80 dark:text-[#c42727]/70" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                      </svg>
                      <span>Search for songs using the search bar</span>
                    </div>
                    <div class="flex items-center gap-2">
                      <svg class="w-5 h-5 text-[#c42727]/80 dark:text-[#c42727]/70" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                      </svg>
                      <span>Click the "+" button to add songs</span>
                    </div>
                    <div class="flex items-center gap-2">
                      <svg class="w-5 h-5 text-[#c42727]/80 dark:text-[#c42727]/70" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"/>
                      </svg>
                      <span>Enjoy your music!</span>
                    </div>
                  </div>
                </div>
              }
            </ul>
          </div>

          @if (playlistSongs()!.size > 0) {
            <button [ngClass]="{'blur-sm': showRemovePopupSignal()}" (click)="showRemovePopup()"
                    class="group relative w-full mt-6 px-4 py-3 text-sm font-medium
                           bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700
                           hover:border-red-200 dark:hover:border-red-900/50
                           hover:bg-red-50 dark:hover:bg-red-900/20
                           transition-all duration-200 ease-in-out
                           flex items-center justify-center gap-2 rounded-md" type="button">
              <svg class="w-4 h-4 text-gray-500 dark:text-gray-400 group-hover:text-red-600 dark:group-hover:text-red-500
                        transition-colors duration-200"
                   fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
              </svg>
              <span class="text-gray-700 dark:text-gray-300 group-hover:text-red-600 dark:group-hover:text-red-500
                          transition-colors duration-200">
                Remove all songs
              </span>
            </button>

            <div [ngClass]="{'!flex': showRemovePopupSignal()}"
                 class="absolute hidden top-0 left-0 w-full h-full bg-black/50 backdrop-blur-sm
                 transition-opacity duration-300 ease-in-out
                 flex items-center justify-center z-50">
              <div class="bg-white dark:bg-gray-800 rounded-lg shadow-2xl transform transition-all duration-300 ease-in-out
                          max-w-md w-[90%] mx-4 scale-100 opacity-100 overflow-hidden"
                   [ngClass]="{'!scale-100 !opacity-100': showRemovePopupSignal(), 'scale-95 opacity-0': !showRemovePopupSignal()}">
                <div class="p-6">
                  <div class="flex items-center justify-center mb-4">
                    <div class="w-12 h-12 mb-6 relative">
                      <div class="absolute inset-0 bg-red-100 dark:bg-red-900/30 rounded-full"></div>
                      <svg class="w-6 h-6 text-red-600 dark:text-red-500 relative" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                      </svg>
                    </div>
                  </div>
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white text-center mb-2">
                    Remove All Songs?
                  </h3>
                  <p class="text-sm md:text-base text-gray-600 dark:text-gray-300 text-center mb-6">
                    This action cannot be undone. Are you sure you want to remove all songs from your playlist?
                  </p>
                  <div class="flex flex-col sm:flex-row gap-3 justify-center">
                    <button (click)="showRemovePopupSignal.set(false)"
                            class="w-full sm:w-auto px-4 py-2 text-sm font-medium text-gray-700 bg-white border
                                   border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none
                                   focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors
                                   dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600
                                   dark:hover:bg-gray-600 dark:focus:ring-gray-500">
                      Cancel
                    </button>
                    <button (click)="removeAllSongs()"
                            class="w-full sm:w-auto px-4 py-2 text-sm font-medium text-white bg-red-600
                                   rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2
                                   focus:ring-offset-2 focus:ring-red-500 transition-colors
                                   dark:bg-red-500 dark:hover:bg-red-600">
                      Remove All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        }

        <!--Export-->
        @if (!listTabSelected()) {
          <div class="flex flex-col w-full max-w-2xl mx-auto overflow-y-auto px-4 h-[calc(100dvh-200px)]">
            <div class="text-center mb-8">
              <h2 class="text-xl font-semibold text-gray-900 dark:text-white mb-2">Export Your Playlist</h2>
              @if (!chosenSupplier()) {
                <p class="text-sm text-gray-600 dark:text-gray-400">Choose your preferred music platform</p>
              }
            </div>

            <div class="relative mt-4">
              <!-- Back button -->
              @if (chosenSupplier()) {
                <button (click)="chosenSupplier.set(undefined)"
                        class="absolute -top-12 left-0 flex items-center gap-2 text-gray-600 dark:text-gray-300 
                               hover:text-gray-900 dark:hover:text-white transition-colors duration-200">
                  <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
                  </svg>
                  <span class="text-sm">Back to platforms</span>
                </button>
              }

              <!-- Supplier Grid -->
              <div class="grid grid-cols-1 sm:grid-cols-3 gap-4"
                   [class.mb-8]="!chosenSupplier()"
                   [ngClass]="{'grid': !chosenSupplier(), 'block': chosenSupplier()}">
                <!-- Spotify -->
                <button (click)="showPlaylistName('spotify')"
                        [class.ring-2]="chosenSupplier() === 'spotify'"
                        [class.ring-[#1DB954]]="chosenSupplier() === 'spotify'"
                        [ngClass]="{'hidden': chosenSupplier() && chosenSupplier() !== 'spotify',
                                  'w-full sm:w-80 mx-auto': chosenSupplier() === 'spotify'}"
                        class="group relative flex flex-col items-center p-4 rounded-xl border border-gray-200 
                               dark:border-gray-700 hover:border-[#1DB954] dark:hover:border-[#1DB954] 
                               transition-all duration-300 ease-in-out">
                  <div class="w-12 h-12 mb-3">
                    <svg class="w-full h-full text-[#1DB954]" viewBox="0 0 168 168">
                      <path fill="currentColor"
                            d="m83.996 0.277c-46.249 0-83.743 37.493-83.743 83.742 0 46.251 37.494 83.741 83.743 83.741 46.254 0 83.744-37.49 83.744-83.741 0-46.246-37.49-83.738-83.745-83.738l0.001-0.004zm38.404 120.78c-1.5 2.46-4.72 3.24-7.18 1.73-19.662-12.01-44.414-14.73-73.564-8.07-2.809 0.64-5.609-1.12-6.249-3.93-0.643-2.81 1.11-5.61 3.926-6.25 31.9-7.291 59.263-4.15 81.337 9.34 2.46 1.51 3.24 4.72 1.73 7.18zm10.25-22.805c-1.89 3.075-5.91 4.045-8.98 2.155-22.51-13.839-56.823-17.846-83.448-9.764-3.453 1.043-7.1-0.903-8.148-4.35-1.04-3.453 0.907-7.093 4.354-8.143 30.413-9.228 68.222-4.758 94.072 11.127 3.07 1.89 4.04 5.91 2.15 8.976v-0.001zm0.88-23.744c-26.99-16.031-71.52-17.505-97.289-9.684-4.138 1.255-8.514-1.081-9.768-5.219-1.254-4.14 1.08-8.513 5.221-9.771 29.581-8.98 78.756-7.245 109.83 11.202 3.73 2.209 4.95 7.016 2.74 10.733-2.2 3.722-7.02 4.949-10.73 2.739z"/>
                    </svg>
                  </div>
                  <span class="text-sm font-medium text-gray-900 dark:text-white">Spotify</span>
                  <span class="text-xs text-green-600 mt-1">&nbsp;</span>
                </button>

                <!-- YouTube -->
                <button disabled
                        [class.ring-2]="chosenSupplier() === 'youtube'"
                        [class.ring-[#FF0000]]="chosenSupplier() === 'youtube'"
                        [ngClass]="{'hidden': chosenSupplier() && chosenSupplier() !== 'youtube',
                                  'w-full sm:w-80 mx-auto': chosenSupplier() === 'youtube'}"
                        class="group relative flex flex-col items-center p-4 rounded-xl border border-gray-200
                               dark:border-gray-700 hover:border-[#FF0000] dark:hover:border-[#FF0000] 
                               transition-all duration-300 ease-in-out">
                  <div class="w-12 h-12 mb-3">
                    <svg class="w-full h-full text-[#FF0000]" viewBox="0 0 24 24">
                      <path fill="currentColor"
                            d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568H9.545zM5.818 12l6.273-3.568L5.818 8.432V15.568z"/>
                    </svg>
                  </div>
                  <span class="text-sm font-medium text-gray-400 dark:text-gray-500">YouTube</span>
                  <span class="text-xs text-gray-400 dark:text-gray-500 mt-1">Coming Soon</span>
                </button>

                <!-- Apple Music -->
                <button disabled
                        [class.ring-2]="chosenSupplier() === 'apple'"
                        [class.ring-black]="chosenSupplier() === 'apple'"
                        [ngClass]="{'hidden': chosenSupplier() && chosenSupplier() !== 'apple',
                                  'w-full sm:w-80 mx-auto': chosenSupplier() === 'apple'}"
                        class="group relative flex flex-col items-center p-4 rounded-xl border border-gray-200
                               dark:border-gray-700 hover:border-black dark:hover:border-white 
                               transition-all duration-300 ease-in-out">
                  <div class="w-12 h-12 mb-3">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Apple_Music_logo.svg"
                         alt="Apple Music"
                         class="w-full h-full dark:invert">
                  </div>
                  <span class="text-sm font-medium text-gray-400 dark:text-gray-500">Apple Music</span>
                  <span class="text-xs text-gray-400 dark:text-gray-500 mt-1">Coming Soon</span>
                </button>

                @if (isUserWhiteLabel()) {
                  <!-- CSV Export -->
                  <button (click)="exportToCsv()"
                          class="group relative flex flex-col items-center p-4 rounded-xl border border-gray-200 
                                 dark:border-gray-700 hover:border-[#185C37] dark:hover:border-[#185C37] 
                                 transition-all duration-200 focus:outline-none">
                    <div class="w-12 h-12 mb-3">
                      <svg class="w-full h-full text-gray-900 dark:text-white" viewBox="0 0 24 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13 3H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V9M13 3V8C13 8.55228 13.4477 9 14 9H19"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 12V18M12 18L9 15M12 18L15 15" stroke="currentColor" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <span class="text-sm font-medium text-gray-900 dark:text-white">CSV Export</span>
                    <span class="text-xs text-green-600 dark:text-green-400">Available</span>
                  </button>
                }
              </div>

              <!-- Playlist Form -->
              @if (chosenSupplier(); as supplier) {
                <div class="w-full sm:w-80 mx-auto transition-all duration-300 ease-in-out mt-4"
                     [ngClass]="{'opacity-100 translate-y-0': supplier, 'opacity-0 translate-y-4': !supplier}">
                  <form (submit)="onSubmit()" class="space-y-4">
                    <div class="relative">
                      <input type="text" [formControl]="playlistName" name="playlistName" required
                             class="block w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700
                                    focus:ring-2 focus:ring-[#1DB954] focus:border-transparent dark:bg-gray-800
                                    text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
                             placeholder="Enter your playlist name">
                    </div>

                    <button type="submit"
                            [ngClass]="{
                              'bg-[#1DB954] hover:bg-[#1DB954]/90': supplier === 'spotify',
                              'bg-[#FF0000] hover:bg-[#FF0000]/90': supplier === 'youtube',
                              'bg-black hover:bg-black/90 dark:bg-white dark:hover:bg-white/90 dark:text-black': supplier === 'apple'
                            }"
                            class="w-full flex items-center justify-center px-4 py-3 rounded-lg
                                   text-white font-medium transition-colors duration-200 
                                   focus:outline-none focus:ring-2 focus:ring-offset-2 
                                   dark:focus:ring-offset-gray-900">
                      Create Playlist
                    </button>
                  </form>

                  @if (playlistUrl(); as url) {
                    <div
                        class="mt-4 p-4 rounded-xl border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800/50">
                      <div class="flex items-center justify-between mb-4">
                        <h3 class="text-lg font-medium text-gray-900 dark:text-white">Your Playlist is Ready!</h3>
                        <a [href]="url" target="_blank"
                           class="text-sm text-[#1DB954] hover:text-[#1DB954]/80 font-medium">
                          Open
                          in {{ supplier === 'spotify' ? 'Spotify' : supplier === 'youtube' ? 'YouTube' : 'Apple Music' }}
                        </a>
                      </div>

                      <div class="relative flex items-center mb-4">
                        <input type="text" [value]="url" readonly
                               class="block w-full pr-24 py-2 pl-3 rounded-lg border border-gray-200 
                                      dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-600 
                                      dark:text-gray-300 text-sm">
                        <button (click)="copyToClipboard(url)"
                                class="absolute right-2 px-3 py-1 text-xs font-medium text-[#1DB954] 
                                       hover:text-[#1DB954]/80 transition-colors duration-200">
                          Copy Link
                        </button>
                      </div>

                      <div class="flex flex-wrap items-center justify-between gap-4">
                        <mypart-social-sharing class="w-1/3" [link]="playlistUrl()!"
                                               source="playlist"></mypart-social-sharing>

                        <button (click)="emailPlaylist(url)"
                                class="flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 
                                       bg-white border border-gray-200 rounded-lg hover:bg-gray-50 
                                       focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1DB954]
                                       dark:bg-gray-800 dark:text-gray-300 dark:border-gray-700 
                                       dark:hover:bg-gray-700 transition-all duration-200 ease-in-out">
                          <svg class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2zM9 19V5l12-3"/>
                          </svg>
                          Email
                        </button>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>

    </div>
  </div>

  @if (loading$ | async) {
    <mypart-spinner-loading></mypart-spinner-loading>
  }
</div>

<!--@if (youtube_id(); as youtube_id) {-->
<!--  <iframe [id]="youtube_id" [src]="iframeSrc" allow="autoplay"-->
<!--          allowfullscreen class="w-full h-full" enablejsapi="1" referrerpolicy="origin"></iframe>-->
<!--}-->
