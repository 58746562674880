<div class="admin-panel h-screen w-screen overflow-hidden">
  <div class="gradient-bg absolute inset-0"></div>
  <div class="dark-overlay absolute inset-0"></div>
  <div class="relative flex h-full z-10">
    <!-- Sidebar -->
    <div [class]="'sidebar transition-all duration-300 ' + (isSidebarOpen ? 'w-64' : 'w-16')">
      <!-- Sidebar Header -->
      <div class="flex h-16 items-center justify-center px-4">
        <h2 [style.opacity]="isSidebarOpen ? 1 : 0"
            [style.visibility]="isSidebarOpen ? 'visible' : 'hidden'"
            class="text-lg font-semibold text-white absolute left-4">
          Admin Panel
        </h2>
        <button (click)="toggleSidebar()"
                [class.ml-auto]="isSidebarOpen"
                class="action-button rounded p-2 hover:bg-white/10">
          <svg [style.transform]="isSidebarOpen ? 'rotate(0)' : 'rotate(180deg)'"
               class="h-6 w-6 text-white transform transition-transform duration-300"
               fill="none"
               stroke="currentColor"
               viewBox="0 0 24 24">
            <path d="M15 19l-7-7 7-7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>
        </button>
      </div>

      <!-- Table List -->
      <div class="flex-1 overflow-y-auto px-2 py-4">
        @if (allTableNames && allTableNames.data) {
          @for (tableName of allTableNames.data; track tableName; let i = $index) {
            <button
                (click)="selectTable(tableName)"
                [title]="formatTableName(tableName)"
                class="table-button mb-1 w-full rounded-lg px-4 py-2 text-left text-white transition-all"
                [class]="selectedTable === tableName ? 'active' : 'hover:bg-white/10'">
              @if (isSidebarOpen) {
                <span>{{ i + 1 }}. {{ formatTableName(tableName) }}</span>
              } @else {
                <span>{{ i + 1 }}.</span>
              }
            </button>
          }
        }
      </div>
    </div>

    <!-- Main Content -->
    <div class="flex flex-1 flex-col overflow-hidden">
      <!-- Table Actions -->
      <div class="mb-6 flex items-center justify-between gap-4 p-6">
        <div class="flex flex-1 items-center gap-4">
          <h1 class="text-2xl font-bold text-white">
            {{ selectedTable ? formatTableName(selectedTable) : 'Select a Table' }}
          </h1>
          <div class="relative flex-1 max-w-md">
            <input (input)="onSearch($event)"
                   class="w-full rounded-lg border border-white/20 bg-white/10 pl-10 pr-4 py-2 text-sm text-white placeholder-white/50 backdrop-blur-sm focus:border-white/30 focus:outline-none focus:ring-1 focus:ring-white/30"
                   placeholder="Search in all fields..."
                   type="text">
            <svg class="absolute left-3 top-2.5 h-4 w-4 text-white/50" fill="currentColor" viewBox="0 0 20 20">
              <path clip-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    fill-rule="evenodd"/>
            </svg>
          </div>
        </div>
        <div class="flex items-center gap-2">
          @if (!isEditing) {
            <button
                (click)="startEditing()"
                class="action-button rounded-lg bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-blue-600">
              Edit Table
            </button>
          }
          @if (isEditing) {
            <button
                (click)="addNewRow()"
                class="action-button rounded-lg bg-green-500 px-4 py-2 text-sm font-medium text-white hover:bg-green-600">
              + Add Row
            </button>
          }
          @if (isEditing) {
            <button (click)="saveChanges()"
                    [disabled]="!hasChanges()"
                    [class]="'action-button rounded-lg px-4 py-2 text-sm font-medium text-white ' + 
                             (hasChanges() ? 'bg-blue-500 hover:bg-blue-600' : 'bg-blue-300 cursor-not-allowed')">
              Save
            </button>
            <button (click)="cancelEditing()"
                    class="action-button rounded-lg bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-600">
              Cancel
            </button>
          }
          <button
              (click)="goHome()"
              class="action-button rounded-lg bg-white/10 px-4 py-2 text-sm font-medium text-white hover:bg-white/20">
            Home
          </button>
        </div>
      </div>

      <!-- Dynamic Table -->
      @if (selectedTable && currentTableData && currentTableData.length > 0) {
        <div class="table-container flex-1 overflow-y-auto p-6 m-4">
          <div class="h-full overflow-auto" #tableContainer>
            <table class="min-w-full table-fixed divide-y divide-gray-200 text-sm border-separate border-spacing-0">
              <!-- Table Header -->
              <thead class="sticky top-0 z-10">
              <tr class="border-b border-gray-200 bg-gray-50">
                @for (column of getTableColumns(); track column) {
                  <th
                      [class]="getColumnClass(column)"
                      [title]="formatColumnName(column)"
                      [ngClass]="{'id-column': column === 'id'}"
                      class="group min-w-fit h-12 border-b border-gray-200 !bg-[#e7e9ec] px-3 text-left text-sm
                      font-bold uppercase tracking-wider text-gray-700 cursor-pointer"
                      (click)="sortData(column)">
                    <div class="flex items-center space-x-1">
                      <span class="truncate font-bold">{{ formatColumnName(column) }}</span>
                      <div class="flex flex-col">
                        <svg
                            [class]="'h-2 w-2 ' + (getSortIcon(column) === 'asc' ? 'text-gray-700' : 'text-gray-400')"
                            viewBox="0 0 20 20" fill="currentColor">
                          <path d="M5 10l5-5 5 5z"/>
                        </svg>
                        <svg
                            [class]="'h-2 w-2 -mt-0.5 ' + (getSortIcon(column) === 'desc' ? 'text-gray-700' : 'text-gray-400')"
                            viewBox="0 0 20 20" fill="currentColor">
                          <path d="M5 10l5 5 5-5z"/>
                        </svg>
                      </div>
                    </div>
                  </th>
                }
                @if (isEditing) {
                  <th class="sticky right-0 w-16 bg-gray-50 px-3"></th>
                }
              </tr>
              </thead>
              <!-- Table Body -->
              <tbody class="divide-y divide-gray-100 bg-white">
              <!-- Add New Row Button -->
                @if (isEditing) {
                  <tr class="h-8 hover:bg-gray-50">
                    <td [attr.colspan]="getTableColumns().length + 1" class="px-3">
                      <button (click)="addNewRow()"
                              class="w-full rounded border border-dashed border-gray-300 py-1 text-xs text-gray-600 hover:border-gray-400 hover:text-gray-800">
                        + Add New Row
                      </button>
                    </td>
                  </tr>
                }
              <!-- Table Rows -->
                @for (row of filteredData; track row; let rowIndex = $index) {
                  <tr
                      class="h-8 hover:bg-gray-50">
                    @for (column of getTableColumns(); track column) {
                      <td
                          [class]="getColumnClass(column)"
                          class="group relative border-b border-gray-100 px-3">
                        <!-- Image Cell -->
                        @if (isImageColumn(column)) {
                          <div class="relative flex items-center gap-2">
                            @if (isEditing) {
                              @if (row[column]) {
                                <img [src]="row[column]"
                                     alt="Preview"
                                     class="h-12 w-12 cursor-pointer object-cover"
                                     (click)="openImageUpload(row, column)">
                              } @else {
                                <button (click)="openImageUpload(row, column)"
                                        class="action-button rounded p-1 mr-4 hover:bg-gray-100">
                                  <svg class="h-4 w-4 text-gray-600" fill="none" stroke="currentColor"
                                       viewBox="0 0 24 24">
                                    <path d="M12 4v16m8-8H4" stroke-linecap="round"
                                          stroke-linejoin="round" stroke-width="2"/>
                                  </svg>
                                </button>
                              }
                            } @else {
                              @if (row[column]) {
                                <img [src]="row[column]"
                                     alt="Preview"
                                     class="h-12 w-12 cursor-pointer object-cover"
                                     (click)="showImagePreview($event, row[column])">
                              }
                            }
                          </div>
                        } @else if (isBooleanField(column, row[column])) {
                          @if (isEditing) {
                            <div class="toggle-radio">
                              <input type="radio" [name]="'toggle_' + column + '_' + row.id"
                                     [id]="'yes_' + column + '_' + row.id"
                                     [checked]="row[column]"
                                     (change)="row[column] = true;">
                              <input type="radio" [name]="'toggle_' + column + '_' + row.id"
                                     [id]="'no_' + column + '_' + row.id"
                                     [checked]="!row[column]"
                                     (change)="row[column] = false;">
                              <div class="switch">
                                <label [for]="'yes_' + column + '_' + row.id">Yes</label>
                                <label [for]="'no_' + column + '_' + row.id">No</label>
                                <span></span>
                              </div>
                            </div>
                          } @else {
                            <div class="toggle-radio disabled">
                              <input type="radio" [name]="'toggle_' + column + '_' + row.id"
                                     [id]="'yes_' + column + '_' + row.id"
                                     [checked]="row[column]" disabled>
                              <input type="radio" [name]="'toggle_' + column + '_' + row.id"
                                     [id]="'no_' + column + '_' + row.id"
                                     [checked]="!row[column]" disabled>
                              <div class="switch">
                                <label [for]="'yes_' + column + '_' + row.id">Yes</label>
                                <label [for]="'no_' + column + '_' + row.id">No</label>
                                <span></span>
                              </div>
                            </div>
                          }
                        } @else {
                          @if (isEditing && column !== 'id') {
                            @if (isLongTextField(column)) {
                              <textarea
                                  [value]="row[column]"
                                  (input)="updateCellValue(row, column, $event)"
                                  rows="2"
                                  class="w-full rounded border border-gray-300 px-2 py-1 text-xs"></textarea>
                            } @else {
                              <input type="text"
                                     [value]="row[column]"
                                     (input)="updateCellValue(row, column, $event)"
                                     class="w-full rounded border border-gray-300 px-2 py-1 text-xs">
                            }
                          } @else {
                            <div class="relative max-h-[2.4em] overflow-hidden">
                              <p [class]="'truncate text-xs ' + (column === 'id' ? 'text-gray-500' : 'text-gray-900')"
                                 [title]="row[column]">
                                {{ row[column] || '-' }}
                              </p>
                              @if (hasLongText(row[column])) {
                                <button
                                    (click)="showFullText(row[column], formatColumnName(column))"
                                    class="absolute right-0 top-0 hidden rounded-sm bg-white px-1 text-[10px] text-blue-600 group-hover:block">
                                  More
                                </button>
                              }
                            </div>
                          }
                        }
                        <!-- Regular Cell -->
                      </td>
                    }
                    <!-- Action Column -->
                    @if (isEditing) {
                      <td class="sticky right-0 w-16 bg-white px-3">
                        <div class="flex justify-end">
                          <button (click)="deleteRow(rowIndex)"
                                  class="rounded p-1 text-gray-400 hover:bg-red-50 hover:text-red-600">
                            <svg class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                            </svg>
                          </button>
                        </div>
                      </td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      }

      <!-- Loading Overlay -->
      @if (isLoading) {
        <div class="loading-overlay">
          <div class="loading-animation">
            <span class="music-note">♪</span>
            <span class="music-note">♫</span>
            <span class="music-note">♬</span>
            <span class="music-note">🎵</span>
            <span class="music-note">🎼</span>
            <span class="music-note">🎶</span>
          </div>
          <div class="loading-text">
            {{ loadingMessage }}
            @if (uploadProgress > 0 && uploadProgress < 100) {
              <div>
                {{ uploadProgress }}%
              </div>
            }
          </div>
        </div>
      }

      <!-- Image Preview Modal -->
      @if (previewImage) {
        <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
             (click)="hideImagePreview()">
          <div class="relative max-h-[80vh] max-w-[80vw] overflow-hidden rounded-lg bg-white p-2">
            <img [src]="previewImage"
                 [alt]="'Preview'"
                 class="max-h-[calc(80vh-1rem)] max-w-[calc(80vw-1rem)] object-contain">
            <button (click)="hideImagePreview()"
                    class="absolute right-2 top-2 rounded-full bg-white p-1 text-gray-600 shadow-lg hover:text-gray-900">
              <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
              </svg>
            </button>
          </div>
        </div>
      }

      <!-- Full Text Modal -->
      @if (fullTextModal) {
        <div
            class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            (click)="closeFullText()">
          <div class="max-h-[80vh] w-[600px] overflow-y-auto rounded-lg bg-white p-6"
               (click)="$event.stopPropagation()">
            <div class="mb-4 flex items-center justify-between">
              <h3 class="text-lg font-medium">{{ fullTextColumnName | titlecase }} - Full Text</h3>
              <button (click)="closeFullText()"
                      class="rounded p-1 hover:bg-gray-100">
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"/>
                </svg>
              </button>
            </div>
            <div class="whitespace-pre-wrap">{{ fullTextContent }}</div>
          </div>
        </div>
      }
    </div>
  </div>
</div>
