<div #curatedPlaylistsPage
     class="z-40 fixed top-0 left-0 right-0 bottom-0 hidden p-4 overflow-x-hidden overflow-y-hidden md:inset-0
      h-full w-full light-gradient dark:dark-gradient"
     id="curated-playlist-page"
     tabindex="-1">
  <div class="relative w-full h-full">
    @if (isLoading()) {
      <mypart-spinner-loading></mypart-spinner-loading>
    }
    <button
        (click)="hideModal()"
        class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
       rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
        type="button">
      <svg aria-hidden="true" class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"/>
      </svg>
      <span class="sr-only">Close modal</span>
    </button>

    <div class="w-full flex flex-wrap items-center justify-center sm:flex-row">
      <!--Title-->
      <div class="w-full order-last sm:order-none sm:w-auto text-center mt-4 sm:mt-0">
        <h1 class="text-xl leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
          Top Playlists on Songhunt
        </h1>
        <h2 class="text-base tracking-normal text-gray-900 md:text-lg dark:text-white">
          Check out our favorite AI-curated playlists
        </h2>
      </div>
    </div>

    <div class="md:p-4 my-4 h-[90%] w-full flex justify-center">
      <div class="w-full grid gap-6 sm:grid-cols-fit-playlists overflow-y-auto">
        @for (playlist of curatedPlaylists(); track playlist.id) {
          <!-- Main playlist card container -->
          <div
              class="w-full flex sm:flex-col items-start sm:items-center space-x-4 sm:space-x-0 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">

            <!-- Left Side (small screens) / Top (large screens) - Playlist Image -->
            <div class="flex-shrink-0">
              <a [href]="playlist.songhuntLink"
                 class="w-[96px] h-[96px] sm:w-full sm:h-auto object-cover rounded-lg"
                 title="Explore the '{{playlist.name}}' playlist on Songhunt">
                <img imageLoader [src]="playlist.imageUrl"
                     [attr.alt]="'Cover image for {{playlist.name}} playlist'"
                     class="w-[96px] h-[96px] sm:w-full sm:h-auto object-cover rounded-lg"
                     loading="lazy">
              </a>
            </div>

            <!-- Right Side (small screens) / Bottom (large screens) - Playlist Info -->
            <div class="flex-1 min-w-0 w-full sm:mt-4">
              <!-- Playlist Name -->
              <p class="text-sm md:text-base font-bold text-gray-900 dark:text-white">
                {{ playlist.name }}
              </p>

              <!-- Links - Songhunt and Spotify -->
              <div class="flex space-x-4 mt-2">
                <span class="text-gray-900 dark:text-white text-sm">Listen On:</span>
                <a [href]="playlist.songhuntLink"
                   class="w-[74px] h-[24px] relative items-center logo-container"
                   title="Explore the '{{playlist.name}}' playlist on Songhunt">
                  @if (appTheme() === 'dark') {
                    <img imageLoader [fill]="true" [attr.alt]="'Songhunt Dark Logo'" loading="lazy"
                         ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/logo_dark.webp">
                  } @else {
                    <img imageLoader [fill]="true" [attr.alt]="'Songhunt Light Logo'" loading="lazy"
                         ngSrc="https://d382nvfdu38z2f.cloudfront.net/songhunt/logo_light.webp">
                  }
                </a>

                <a [href]="playlist.spotifyLink"
                   class="w-[74px] h-[24px] relative items-center logo-container"
                   target="_blank" title="Explore the '{{playlist.name}}' playlist on Spotify">
                  <img imageLoader [fill]="true" [attr.alt]="'Spotify Logo'" loading="lazy" ngSrc="assets/spotify.png">
                </a>
              </div>

              <!-- Playlist Description -->
              <p class="text-xs md:text-sm text-gray-500 dark:text-gray-400 mt-2">
                {{ playlist.description }}
              </p>
            </div>
          </div>
        }
      </div>
    </div>

  </div>
</div>
