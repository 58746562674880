import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {GlobalStateService} from '../../services/global-state.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {pixelateInOut} from '../../animations/pixelateInOut';
import {FiltersStateService} from '../../services/filters-state.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpReqService} from '../../services/http-req.service';
import {UserStateService} from '../../services/user-state.service';
import {SpotifyUserAccountService} from '../../services/spotify/spotify-user-account.service';
import {switchMap} from 'rxjs/operators';
import {lastValueFrom, tap} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {User} from '../../interfaces/user';
import {DomSanitizer, Meta, SafeHtml} from '@angular/platform-browser';
import {YoutubeUserAccountService} from '../../services/youtube/youtube-user-account.service';
import {YoutubeBaseApiService} from '../../services/youtube/youtube-base-api.service';
import {HttpResponse} from '@angular/common/http';
import {ToastService} from '../../services/toast.service';
import {WhiteLabelUserService} from '../../services/white-label-user.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {MobileDeviceService} from '../../services/mobile-device.service';
import {ModalService} from '../../services/modal.service';
import {ModalType} from '../../interfaces/modal-types.enum';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [pixelateInOut,
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('100ms .5s ease-in-out', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('.5s ease-in-out', style({transform: 'translateX(100%)'}))
      ])
    ])],
  standalone: false
})
export class MainContentComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollTarget', {static: false, read: ElementRef}) scrollTarget: ElementRef | undefined;
  skeletonArray = Array.from({length: 30}, (_, i) => ({[i]: i}));
  songs = this.globalStateService.songs;
  showNoMoreResults = this.globalStateService.showNoMoreResults;
  showCuratedPlaylists = this.globalStateService.showCuratedPlaylists;
  showBigSearchHomePage = this.globalStateService.showBigSearchHomePage;
  showBigSearchHomePageIsUndefined = this.globalStateService.showBigSearchHomePageIsUndefined;

  showPlaylist = this.globalStateService.showPlaylist;
  isMaxTabletDevice = this.mobileDeviceService.isMaxTabletDevice;
  noMoreResultsMessage = this.globalStateService.noMoreResultsMessage;
  filtersVisible = this.filtersStateService.filtersVisible;
  currentPlaylistSize = 0;

  private loginWhiteLabelUser = false;

  constructor(private globalStateService: GlobalStateService,
              private filtersStateService: FiltersStateService,
              private httpReqService: HttpReqService,
              private route: ActivatedRoute,
              private router: Router,
              private userStateService: UserStateService,
              private spotifyUserAccountService: SpotifyUserAccountService,
              private youtubeUserAccountService: YoutubeUserAccountService,
              private sanitizer: DomSanitizer,
              private metaTagService: Meta,
              private youtubeBaseApiService: YoutubeBaseApiService,
              private toast: ToastService,
              private modalService: ModalService,
              private whiteLabelUserService: WhiteLabelUserService,
              private mobileDeviceService: MobileDeviceService) {
    this.httpReqService.getSignupSvg();

    effect(() => {
      const playlistSongs = this.globalStateService.playlistSongs();
      const imageElement = document.querySelector('.scale-effect img');
      const spanElement = document.querySelector('.new-underline-effect');

      if (this.currentPlaylistSize > playlistSongs.size) {
        // Playlist size decreased, scale down the icon to 90% and back to normal size

        imageElement?.classList.add('scale-down');
        // Trigger fill-down and backwards effect when playlist size decreases
        spanElement?.classList.add('fill-down');
      }

      if (this.currentPlaylistSize < playlistSongs.size) {
        // Playlist size increased, scale up the icon to 110% and back to normal size
        imageElement?.classList.add('scale-up');

        // Trigger fill-up and backwards effect when playlist size increases
        spanElement?.classList.add('fill-up');
      }

      setTimeout(() => {
        imageElement?.classList.remove('scale-up');
        imageElement?.classList.remove('scale-down');
        spanElement?.classList.remove('fill-up');
        spanElement?.classList.remove('fill-down');
      }, 1000);
      this.currentPlaylistSize = playlistSongs.size;
    });
  }

  ngOnInit() {
    // this.updateCanonicalMetaTag();
    const currentUrl = window.location.href;

    if (this.whiteLabelUserService.whiteLabelEmail) {
      this.globalStateService.setShowBigSearchHomePage(true, 'MainContent-if (this.whiteLabelUserService.whiteLabelEmail)');
      this.loginWhiteLabelUser = true;
      const email = this.whiteLabelUserService.whiteLabelEmail;
      const pass = this.whiteLabelUserService.whiteLabelPass;
      if (email && pass) {
        setTimeout(() => {
          this.httpReqService.login(email, pass)
            .subscribe({
              next: (response: HttpResponse<User>) => {
                this.userStateService.setUser(response.body);
              },
              error: (e) => {
                this.toast.warning('We could not sign you in automatically, please sign in again.');
              },
              complete: () => {
                this.whiteLabelUserService.whiteLabelEmail = undefined;
                this.whiteLabelUserService.whiteLabelPass = undefined;
              }
            });
        });
      }
    }

    if (currentUrl.includes('/explorer-signup')) {
      this.modalService.open(ModalType.LOGIN_REGISTER, {
        inputs: {
          loginPopupTitle: 'Sign Up for FREE',
          onlyExplorer: true
        }
      });
      this.globalStateService.setShowBigSearchHomePage(true, 'MainContent-explorer-signup');
    }

    if (currentUrl.includes('/signup')) {
      this.globalStateService.setShowBigSearchHomePage(true, 'MainContent- if (currentUrl.includes(\'/signup/\')) {');

      const promotionCode =
        this.route.snapshot.queryParamMap.get('promocode') || this.route.snapshot.queryParamMap.get('amp;promocode');
      const affiliateCode = this.route.snapshot.queryParamMap.get('affiliate');

      let inputs: any = {};

      if (promotionCode) {
        inputs.promotionCodeFromUrl = promotionCode;
      }
      if (affiliateCode) {
        inputs.affiliateCode = affiliateCode;
        inputs.loginPopupTitle = 'You have been invited to Songhunt.ai';
      }
      this.modalService.open(ModalType.LOGIN_REGISTER, {inputs});
    }

    if (currentUrl.includes('affiliate-program/')) {
      this.modalService.open(ModalType.AFFILIATION_LINK);
      this.globalStateService.setShowBigSearchHomePage(true, 'MainContent-affiliate-program');
      window.history.pushState({}, 'Songhunt', this.httpReqService.trimUrlForPopups(window.location.href, 'affiliate-program/'));
    }

    this.startLoadingProcess();

    computed(() => {
      const prompterText = this.filtersStateService.prompterText$.getValue();
      const songFromUrl = this.httpReqService.songFromUrl();
      const artistFromUrl = this.httpReqService.artistFromUrl();

      // If any value is truthy, trigger the action
      if (prompterText || songFromUrl || artistFromUrl) {
        this.scrollToMainContent();
      }
    });
  }

  getSanitizedMessage(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.noMoreResultsMessage()!);
  }

  ngAfterViewInit() {
    this.youtubeBaseApiService.initGoogleAccount();
  }

  scrollToMainContent() {
    setTimeout(() => {
      if (this.scrollTarget) {
        // Get the element's position relative to the entire document
        const elementPosition = this.scrollTarget.nativeElement.offsetTop;

        setTimeout(() => {
          window.scrollTo({
            top: elementPosition - this.globalStateService.navBar?.nativeElement.offsetHeight,
            behavior: 'smooth'
          });
        }, 300);
      }
    });
  }

  private async updateFiltersWithJsonDataAndGetIds() {
    // Fetch filters from both methods
    const dbFilters = await this.httpReqService.getFilters();
    const jsonFilters = await this.httpReqService.getFiltersJson();
    this.globalStateService.publicProjectIds = await this.httpReqService.getProjectsIds();
    this.userStateService.setProjectIds();

    // Create a map to store filters from getFilters() by their id
    const dbFilterMap = new Map<number, any>();
    for (const dbFilterGroup of dbFilters) {
      for (const filter of dbFilterGroup.filters) {
        dbFilterMap.set(filter.id, filter);
      }
    }

    // Iterate through jsonFilters and update them based on dbFilterMap
    for (const jsonFilterGroup of jsonFilters) {
      for (const jsonFilter of jsonFilterGroup.filters) {
        const matchingDbFilter = dbFilterMap.get(jsonFilter.id);
        if (matchingDbFilter) {
          // Update threshold and limit properties in jsonFilter
          jsonFilter.threshold = matchingDbFilter.threshold;
          jsonFilter.limit = matchingDbFilter.limit;
        }
      }
    }

    // Return the updated jsonFilters
    return jsonFilters;
  }

  private handleYoutube(code: any, user: User) {
    this.youtubeUserAccountService.handleAuthenticationCallback(code)
      .pipe(
        switchMap(res => {
          const userEmail = this.userStateService.user()!.email;
          return this.youtubeUserAccountService.saveUserData(res.access_token, res.refresh_token, userEmail).pipe(
            tap(userProfile => {
              user.isYoutubeConnected = true;
              this.userStateService.setUser(user);
            })
          );
        })
      )
      .subscribe({
        next: (value: any) => {
          this.toast.success(`Successfully connected ${value.names[0].displayName} to your account`);
        },
        error: err => {
          this.toast.warning(`Could not connect to your Spotify account. ${environment.toastSupportMessage}`);
        }
      });
  }

  private handleSpotify(code: any, user: User) {
    this.spotifyUserAccountService.handleAuthenticationCallback(code).pipe(
      switchMap(res => {
        const userEmail = this.userStateService.user()!.email;

        return this.spotifyUserAccountService.saveUserData(res.access_token, res.refresh_token, userEmail).pipe(
          tap(userProfile => {
            user.isSpotifyConnected = true;
            this.spotifyUserAccountService.setUserId(userProfile.id);
            this.userStateService.setUser(user);
          })
        );
      })
    ).subscribe({
      next: userProfile => {
        this.toast.success(`Successfully connected ${userProfile.display_name} to your account`);
      },
      error: err => {
        this.toast.error(`Could not connect to your Spotify account. ${environment.toastSupportMessage}`);
      }
    });
  }

  private handleQueryParams(params: Params, user: User) {
    if (Object.keys(params).length === 0) {
      return;
    }
    // -1 is for quick tags with filters
    // -2 is for quick tags with no filters
    let referenceSongId: number | undefined = params['referenceSongIds'] || -1;
    const filterArray: { id: number, state: 'green' | 'red' }[] = [];

    // Iterate over all query parameters to handle filter_ids
    for (const key in params) {
      if (key.startsWith('id_')) {
        const id = +key.split('_')[1];  // Extracts the ID from the key, i.e., 496 from id_496
        const state = params[key];     // The associated state, e.g., 'green' or 'red'

        filterArray.push({id, state});
      }
    }

    const primaryFilterId: number | undefined = params['primaryFilterId'];
    const lyricsContains: string | undefined = params['lyricsContains'];
    const searchInArtist: string | undefined = params['searchInArtist'];
    const multipleArtworksIds: string | undefined = params['multipleArtworksIds'];
    const searchOnThisArtist: string | undefined = params['searchOnThisArtist'];
    const narrative: string | undefined = params['narrative'];

    if (filterArray.length > 0 || primaryFilterId || lyricsContains || searchInArtist || multipleArtworksIds || searchOnThisArtist || narrative) {
      this.filtersStateService.applyFilterFromUrl(filterArray, primaryFilterId, lyricsContains, searchInArtist, multipleArtworksIds, searchOnThisArtist, narrative);
    }

    const sortByIndex: number = params['sortByIndex'];
    if (sortByIndex) {
      const sortByArray = this.filtersStateService.sortByArray();
      this.filtersStateService.changeSelectedSort(sortByArray[sortByIndex]);
    }

    if (referenceSongId === -1 && filterArray.length === 0 && !primaryFilterId && !lyricsContains) {
      referenceSongId = -2;
    }

    let youtubeId: string | undefined = params['youtubeId'];

    if (youtubeId) {
      this.modalService.open(ModalType.YOUTUBE_URL_WINDOW, {
        inputs: {
          youtubeId: youtubeId
        }
      });
    }

    this.filtersStateService.youtubeIdFromUrl.set(youtubeId);

    const currentUrl = window.location.href;

    if (currentUrl.includes('/artist/') || currentUrl.includes('/similar/')) {
      return;
    }

    this.globalStateService.referenceSongFromUrl.set(referenceSongId);
    this.globalStateService.updateGlobalStateSignal = '';


    let stripeParam = params['stripe'];
    if (stripeParam) {
      this.modalService.open(ModalType.PURCHASE_SUCCESS, {
        inputs: {
          isSuccess: stripeParam === 'suc' // or false for failure
        }
      });
      this.router.navigate([''],).then();
    }
    const code = params['code'];
    if (code) {
      if (params['youtube_auth']) {
        this.handleYoutube(code, user);
      } else {
        this.handleSpotify(code, user);
      }
    }
  }

  private updateCanonicalMetaTag() {
    const canonicalURL = 'https://www.mypart.com/songhunt';
    // Remove any existing canonical tags to avoid duplicates
    const existingCanonical = document.querySelector('link[rel=\'canonical\']');
    if (existingCanonical) {
      existingCanonical.remove();
    }
    this.metaTagService.addTag({rel: 'canonical', href: canonicalURL});
  }

  private getCuratedPlaylist() {
    this.httpReqService.getCuratedPlaylists()
      .subscribe({
        next: (curatedPlaylists) => {
          this.globalStateService.curatedPlaylists.set(curatedPlaylists);
          this.globalStateService.errorRetrievingPlaylists = false;
        },
        error: (e) => {
          this.globalStateService.errorRetrievingPlaylists = true;
        }
      });
  }

  private startLoadingProcess(): void {
    const promises: Promise<void>[] = [];

    const featuredArtistPromise = lastValueFrom(this.httpReqService.getFeaturedArtist())
      .then((featuredArtist) => {
        this.globalStateService.featuredArtist.set(featuredArtist);
      })
      .catch(() => {
        return this.getCuratedPlaylist();
      })
      .finally(() => {
        this.getCuratedPlaylist();
      });

    promises.push(featuredArtistPromise);

    if (this.loginWhiteLabelUser) {
      this.loginWhiteLabelUser = false;

      const filtersPromise = this.updateFiltersWithJsonDataAndGetIds()
        .then((filters) => {
          this.filtersStateService.setFilters(filters);
          return new Promise<void>((resolve) => {
            this.route.queryParams.subscribe(params => {
              this.handleQueryParams(params, this.userStateService.user()!);
              resolve(); // Ensure this Promise resolves after queryParams are handled
            });
          });
        });

      promises.push(filtersPromise);
    } else {
      const userDetailsPromise = lastValueFrom(this.httpReqService.getUserDetails())
        .then(async (user) => {
          if (user) {
            if (user.email !== 'shtest@songhunt.com') {
              this.userStateService.setUser(user);
            } else {
              this.userStateService.initPreferences(null);
            }
            const filters = await this.updateFiltersWithJsonDataAndGetIds();
            this.filtersStateService.setFilters(filters);
            return await new Promise<void>((resolve) => {
              this.route.queryParams.subscribe(params => {
                this.handleQueryParams(params, user);
                resolve(); // Ensure this Promise resolves after queryParams are handled
              });
            });
          }
        });

      promises.push(userDetailsPromise);
    }

    // Wait for all promises to complete
    Promise.all(promises).then(() => {
      this.globalStateService.initialLoadingProcessComplete.set(true); // Set to true after everything is done
      console.log('Loading process completed');
    });
  }
}
