<div class="fixed top-0 left-1/2 transform -translate-x-1/2 m-4 z-[15000] toast-container flex flex-col gap-2">
  @for (toast of toasts; track toast.id) {
    <div [@toastAnimation] class="toast" [ngClass]="toast.type">
      <div class="toast-icon">
        <div class="icon" [ngClass]="toast.type"></div>
      </div>
      <div class="toast-content">
        <p [innerHTML]="toast.message"></p>
      </div>
      <button
          (click)="removeToast(toast.id)"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
           rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white toast-close"
          type="button" aria-label="Close notification">
        <svg aria-hidden="true" class="w-3 h-3 close-icon" fill="none" viewBox="0 0 14 14"
             xmlns="http://www.w3.org/2000/svg">
          <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2"/>
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
    </div>
  }
</div>
