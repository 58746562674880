import {ChangeDetectorRef, Component, effect} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {ToastService} from '../../services/toast.service';
import {ToastMessage} from '../../interfaces/toast-message';

@Component({
  selector: 'mypart-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toastAnimation', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateY(-1rem) scale(0.95)'
        }),
        animate('200ms cubic-bezier(0.4, 0, 0.2, 1)', style({
          opacity: 1,
          transform: 'translateY(0) scale(1)'
        }))
      ]),
      transition(':leave', [
        animate('150ms cubic-bezier(0.4, 0, 0.2, 1)', style({
          opacity: 0,
          transform: 'translateY(-1rem) scale(0.95)'
        }))
      ])
    ])
  ],
  standalone: false
})
export class ToastComponent {
  toasts: ToastMessage[] = [];
  private toastTimeouts: Map<string, any> = new Map();
  private checkInterval: any;

  constructor(
    private toastService: ToastService,
    private cdr: ChangeDetectorRef
  ) {
    effect(() => {
      this.toasts = this.toastService.toastsSignal();
      this.manageToasts();
    });
  }

  ngOnInit() {
    // Check for expired toasts every 100ms
    this.checkInterval = setInterval(() => this.checkExpiredToasts(), 100);
  }

  ngOnDestroy() {
    // Clear all timeouts when component is destroyed
    this.clearAllTimeouts();
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  removeToast(id: string) {
    // Clear the timeout for this toast
    if (this.toastTimeouts.has(id)) {
      clearTimeout(this.toastTimeouts.get(id));
      this.toastTimeouts.delete(id);
    }

    // Remove from service
    this.toastService.removeToast(id);
    this.cdr.detectChanges();
  }

  private manageToasts() {
    // Set up timeouts for any new toasts
    this.toasts.forEach(toast => {
      if (!this.toastTimeouts.has(toast.id)) {
        const timeRemaining = toast.expiresAt - Date.now();
        if (timeRemaining > 0) {
          const timeoutId = setTimeout(() => {
            this.removeToast(toast.id);
          }, timeRemaining);
          this.toastTimeouts.set(toast.id, timeoutId);
        } else {
          // Toast already expired
          this.removeToast(toast.id);
        }
      }
    });

    // Clean up timeouts for toasts that no longer exist
    for (const [id, timeout] of this.toastTimeouts.entries()) {
      if (!this.toasts.some(toast => toast.id === id)) {
        clearTimeout(timeout);
        this.toastTimeouts.delete(id);
      }
    }
  }

  private checkExpiredToasts() {
    const now = Date.now();
    const expiredToastIds = this.toasts
      .filter(toast => toast.expiresAt <= now)
      .map(toast => toast.id);

    if (expiredToastIds.length > 0) {
      expiredToastIds.forEach(id => this.removeToast(id));
    }
  }

  private clearAllTimeouts() {
    for (const timeoutId of this.toastTimeouts.values()) {
      clearTimeout(timeoutId);
    }
    this.toastTimeouts.clear();
  }
}
