import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelUserService {
  private _whiteLabelEmail: string | undefined = undefined;

  // Getter for whiteLabelEmail
  get whiteLabelEmail(): string | undefined {
    return this._whiteLabelEmail;
  }

  // Setter for whiteLabelEmail
  set whiteLabelEmail(value: string | undefined) {
    this._whiteLabelEmail = value?.trim(); // Optional: Trim whitespace
  }

  private _whiteLabelPass: string | undefined = undefined;

  // Getter for whiteLabelPass
  get whiteLabelPass(): string | undefined {
    return this._whiteLabelPass;
  }

  // Setter for whiteLabelPass
  set whiteLabelPass(value: string | undefined) {
    this._whiteLabelPass = value;
  }
}
