import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, finalize, mergeMap, Observable, of, retry, throwError, timer} from 'rxjs';
import {LoaderService} from '../services/loader.service';
import {HttpReqService} from '../services/http-req.service';
import {UserStateService} from '../services/user-state.service';
import {ModalService} from '../services/modal.service';
import {ModalType} from '../interfaces/modal-types.enum';
import {environment} from '../../environments/environment.prod';
import {ToastService} from '../services/toast.service';

interface NetworkInformation extends EventTarget {
  readonly effectiveType: '4g' | '3g' | '2g' | 'slow-2g';
  readonly downlink: number;
  readonly rtt: number;
  readonly saveData: boolean;
  onchange: EventListener;
}

interface NavigatorWithConnection extends Navigator {
  readonly connection?: NetworkInformation;
}

enum ConnectionWarningType {
  VERY_SLOW = 'very_slow',
  SLOW = 'slow',
  DEGRADED = 'degraded'
}

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private hasAttemptedRecovery = false;
  private activeRequests: number = 0;
  private lastConnectionCheck = 0;
  private connectionCheckInterval = 10000; // Check connection every 10 seconds at most
  private readonly SLOW_RTT_THRESHOLD = 500; // Round trip time threshold in ms
  private readonly SLOW_DOWNLINK_THRESHOLD = 1.0; // Downlink threshold in Mbps
  private readonly MAX_RETRIES = 3;
  private readonly RETRY_DELAY = 1000; // 1 second
  private shownWarnings = new Set<ConnectionWarningType>();
  private lastToastTime = 0;
  private readonly TOAST_COOLDOWN = 5000; // 5 seconds between toasts

  constructor(private loader: LoaderService,
              private httpReqService: HttpReqService,
              private userStateService: UserStateService,
              private modalService: ModalService,
              private toastService: ToastService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loader.show();
    this.activeRequests++;

    // Skip connection check for certain endpoints
    if (this.shouldSkipConnectionCheck(request.url)) {
      return this.completeRequestNoRecovery(request, next).pipe(
        finalize(() => this.finalizeRequest())
      );
    }

    return this.checkConnectionQuality().pipe(
      mergeMap((quality) => {
        if (!quality) {
          // Instead of throwing error immediately, try to proceed with the request
          // but show a warning to the user
          this.showConnectionWarning();
        }
        return this.checkIfNeedsRecovery(request, next).pipe(
          retry({
            count: this.MAX_RETRIES,
            delay: (error) => {
              if (error instanceof HttpErrorResponse && error.status === 0) {
                return timer(this.RETRY_DELAY);
              }
              return throwError(() => error);
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 0) {
              this.showConnectionError();
            }
            this.handleServerRestartError(error);
            return throwError(() => error);
          }),
          finalize(() => this.finalizeRequest())
        );
      })
    );
  }

  private shouldSkipConnectionCheck(url: string): boolean {
    // Skip connection check for essential resources or specific endpoints
    return url.includes('/assets/') ||
      url.includes('favicon.ico') ||
      url.includes('manifest.json');
  }

  private showConnectionWarning(): void {
    const now = Date.now();
    if (now - this.lastToastTime >= this.TOAST_COOLDOWN) {
      this.toastService.warning('Poor connection detected - some features may be limited', 5000);
      this.lastToastTime = now;
    }
  }

  private showConnectionError(): void {
    const now = Date.now();
    if (now - this.lastToastTime >= this.TOAST_COOLDOWN) {
      this.toastService.error('Connection error - retrying...', 5000);
      this.lastToastTime = now;
    }
  }

  private finalizeRequest(): void {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.loader.hide();
    }
  }

  private showWarningOnce(type: ConnectionWarningType, message: string): void {
    const now = Date.now();
    if (!this.shownWarnings.has(type) && now - this.lastToastTime >= this.TOAST_COOLDOWN) {
      this.toastService.warning(message, 5000);
      this.shownWarnings.add(type);
      this.lastToastTime = now;
    }
  }

  private checkConnectionQuality(): Observable<boolean> {
    const now = Date.now();
    if (now - this.lastConnectionCheck < this.connectionCheckInterval) {
      return of(true); // Skip check if we checked recently
    }
    this.lastConnectionCheck = now;

    // Use modern Network Information API if available
    const nav = navigator as NavigatorWithConnection;
    if (nav.connection) {
      const conn = nav.connection;

      // Check effective connection type
      if (conn.effectiveType === 'slow-2g' || conn.effectiveType === '2g') {
        this.showWarningOnce(
          ConnectionWarningType.VERY_SLOW,
          'Very slow connection detected - some features may be limited'
        );
        return of(true); // Allow but warn
      }

      // Check round trip time and downlink speed
      if (conn.rtt > this.SLOW_RTT_THRESHOLD || conn.downlink < this.SLOW_DOWNLINK_THRESHOLD) {
        this.showWarningOnce(
          ConnectionWarningType.SLOW,
          'Slow connection detected - performance may be affected'
        );
        return of(true); // Allow but warn
      }

      // Add listener for connection changes
      conn.onchange = () => {
        if (conn.effectiveType === 'slow-2g' || conn.effectiveType === '2g') {
          this.showWarningOnce(
            ConnectionWarningType.DEGRADED,
            'Connection speed has degraded - please check your internet'
          );
        }
      };
    }

    // Always return true - we'll handle connection issues through error handling
    return of(true);
  }

  private checkIfNeedsRecovery(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // For other URLs, make a normal call without the custom logic
    if ((!request.url.includes('localhost:4200') && !request.url.includes('mypart.com'))
      || request.url.includes('mypart-webapp/register')) {
      return this.completeRequestNoRecovery(request, next)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.handleServerRestartError(error);
            return throwError(() => error);
          }),
        );
    }

    return this.completeRequestWithRecovery(request, next)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.handleServerRestartError(error);
          return throwError(() => error);
        }),
      );
  }

  private completeRequestNoRecovery(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request);
  }

  private completeRequestWithRecovery(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 503) {
          this.modalService.open(ModalType.LOGIN_ERROR);
          return throwError(() => error);
        }

        if (error.error?.error?.code === 'PAYLOAD_TOO_LARGE') {
          return throwError(() => error);
        }

        if (!this.hasAttemptedRecovery) {
          this.hasAttemptedRecovery = true;

          if (this.userStateService.user()) {
            return next.handle(request);
          }

          return this.httpReqService.loginDefaultUser()
            .pipe(
              mergeMap(() => next.handle(request)),
              catchError(err => throwError(() => error))
            );
        } else {
          this.hasAttemptedRecovery = false;
          return throwError(() => error);
        }
      }),
      finalize(() => {
        this.hasAttemptedRecovery = false;
      }),
    );
  }

  private handleServerRestartError(error: HttpErrorResponse) {
    if (error.status === 511) {
      console.error('Error 511: Network Authentication Required', error);

      if (this.userStateService.user()?.email === environment.defaultUserEmail) {
        window.location.reload();
      } else {
        this.modalService.open(ModalType.LOGIN_REGISTER, {
          inputs: {
            loginPopupTitle: 'An issue has occurred, please sign in again',
            activateHardRefresh: true,
            hideCloseButton: true
          }
        });
      }
    }
  }
}
