import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal, ViewChild} from '@angular/core';
import {initFlowbite} from 'flowbite';
import {UntilDestroy} from '@ngneat/until-destroy';
import {GlobalStateService} from './services/global-state.service';
import {NavBarComponent} from './components/nav-bar/nav-bar.component';
import {distinctUntilChanged, fromEvent, Observable, throttleTime} from 'rxjs';
import {LoaderService} from './services/loader.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {environment} from '../environments/environment.prod';
import {WhiteLabelUserService} from './services/white-label-user.service';
import {AffiliationTimerService} from './services/affiliation-timer.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'mypart-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('0.5s ease-in-out', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('0.5s ease-in-out', style({transform: 'translateX(100%)'}))
      ])
    ])
  ],
  standalone: false
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('navBarComponent', {static: false}) navBarComponent: NavBarComponent | undefined;
  scrollObservable$: Observable<Event> | undefined;
  marginTop = signal(0);
  isLoading = this.loaderService.isLoading;

  showCuratedPlaylists = this.globalStateService.showCuratedPlaylists;
  showPlaylist = this.globalStateService.showPlaylist;
  showAdminPanel = this.globalStateService.showAdminPanel;
  showProfilePage = this.globalStateService.showProfilePage;

  userScroll = false;

  // snowArray = new Array(60).fill(0).map((_, index) => index + 1);

  private mutationObserver!: MutationObserver;

  constructor(private globalStateService: GlobalStateService,
              private loaderService: LoaderService,
              private affiliationTimerService: AffiliationTimerService,
              whiteLabelUserService: WhiteLabelUserService) {
    const currentUrl = window.location.href;

    if (currentUrl.includes('/songhunt')) {
      const newUrl = currentUrl.replace('/songhunt', '') || '/';
      window.history.replaceState({}, '', newUrl);
    }

    globalStateService.initAppTheme();

    // Redirect non-www version (as previously discussed)
    if (currentUrl.startsWith('https://mypart.com')) {
      window.location.href = currentUrl.replace('https://mypart.com', 'https://www.mypart.com');
    }

    const subdomain = this.getSubdomain();
    if (subdomain) {
      this.updateEnvironmentUrlsIfNeeded(subdomain);
    }

    if (window.location.href.includes('isWhiteLabelUser')) {
      const queryEmail = this.getQueryParam('em');
      const queryPass = this.getQueryParam('ps');

      const email = decodeURIComponent(queryEmail!);
      const pass = decodeURIComponent(queryPass!);

      whiteLabelUserService.whiteLabelEmail = email;
      whiteLabelUserService.whiteLabelPass = pass;
    }
  }

  ngOnInit() {
    this.showEnterprise();

    this.initMobileListeners();
  }

  ngAfterViewInit() {
    // this.initializeSnowflakes();

    initFlowbite();

    this.initMutationObserver();

    setTimeout(() => {
      // Get the scroll container element
      const scrollContainer = document.getElementById('results-scroll-container');

      if (scrollContainer) {
        // Replace the window with the scroll container in the fromEvent
        this.scrollObservable$ = fromEvent(scrollContainer, 'scroll')
          .pipe(
            throttleTime(100),
            distinctUntilChanged()
          );

        this.scrollObservable$.subscribe(event => {
          if (this.userScroll) {
            this.handleScrollingOnMobile();
          }

          this.userScroll = false; // Reset the flag after handling the scroll event

          // Check if the user has scrolled to at least half the content height
          if ((scrollContainer.scrollTop + scrollContainer.clientHeight) >= (scrollContainer.scrollHeight * 0.5)) {
            this.loadMore();
          }
        });
      }
    }, 3000);
  }

  ngOnDestroy() {
    this.affiliationTimerService.stopTimer();
  }

  initMutationObserver(): void {
    if (this.globalStateService.navBar?.nativeElement) {
      this.mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' || mutation.type === 'childList' || mutation.type === 'characterData') {
            this.logNavBarHeight();
          }
        });
      });

      this.mutationObserver.observe(this.globalStateService.navBar.nativeElement, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true
      });

      // Log the initial height
      this.logNavBarHeight();
    }
  }

  logNavBarHeight(): void {
    if (this.globalStateService.showBigSearchHomePage()) {
      this.marginTop.set(0);
    } else {
      this.marginTop.set(this.globalStateService.navBar?.nativeElement.offsetHeight);
    }
  }

  loadMore() {
    if (!this.globalStateService.songsLoading() && !this.isLoading && !this.globalStateService.showNoMoreResults()) {
      this.globalStateService.loadMoreSongsEvent.set(true);
    }
  }

  private handleScrollingOnMobile() {
    const filterWasClicked = this.globalStateService.filterWasClickedDontHideFilters();
    // Check for scroll down and screen width less than 640px
    if (window.innerWidth < 640 && !filterWasClicked) {
      this.globalStateService.scrollEventOnMobile.set(true);
    }
    this.globalStateService.filterWasClickedDontHideFilters.set(false);
  }

  private initMobileListeners() {
    if (window.innerWidth < 640) {
      // Mouse down event - common for all devices including iOS
      window.addEventListener('mousedown', () => this.userScroll = true);

      // Key down event - for keyboard interactions (less relevant for iOS unless an external keyboard is used)
      window.addEventListener('keydown', (event) => {
        if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
          this.userScroll = true;
        }
      });

      // Touch start event - common for touch devices including iOS
      // This should work well for most touch interactions on iOS
      window.addEventListener('touchstart', () => this.userScroll = true);

      // Consider adding 'touchmove' for more continuous tracking on iOS
      // 'touchmove' is triggered when a finger is dragged across the screen.
      window.addEventListener('touchmove', () => this.userScroll = true);
    }
  }

  private showEnterprise() {
    console.log(`
 ______
                                ___.--------'------'---------.____
                          _.---'----------------------------------'---.__
                        .'___=]===========================================
,-----------------------..__/.'         >--.______        _______.---'
]====================<==||(__)        .'          '------'
'-----------------------' ----.___--/
     /       /---'                 '/'
    /_______(___USS_SONGHUNT_____/
    '-------------.--------------.'
                   \\________|_.-'
`);
  }

  private getSubdomain(): string | null {
    const host = window.location.host;
    if (host.startsWith('www') || host.startsWith('localhost:')) {
      return null; // No need to change anything if it's "www"
    }
    return host.split('.')[0];
  }

  private updateEnvironmentUrlsIfNeeded(subdomain: string | null): void {
    if (subdomain) {
      environment.baseUrl = environment.baseUrl.replace(/^(https?:\/\/)([^\/]+)/, `$1${subdomain}.mypart.com`);
      environment.baseUrlWithApi = environment.baseUrlWithApi.replace(/^(https?:\/\/)([^\/]+)/, `$1${subdomain}.mypart.com`);
      environment.baseUrlWithApiSh = environment.baseUrlWithApiSh.replace(/^(https?:\/\/)([^\/]+)/, `$1${subdomain}.mypart.com`);
    }
  }

  private getQueryParam(param: string): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  private initializeSnowflakes() {
    document.querySelectorAll('.snow').forEach((snow: any) => {
      snow.style.setProperty('--x', `${Math.random() * 100}vw`);
      snow.style.setProperty('--left-ini', `${Math.random() * 20 - 10}vw`);
      snow.style.setProperty('--left-end', `${Math.random() * 20 - 10}vw`);
      snow.style.setProperty('--duration', `${Math.random() * 10 + 5}s`);
      snow.style.setProperty('--delay', `-${Math.random() * 10}s`);
    });
  }
}
