import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {APP_BASE_HREF, NgOptimizedImage} from '@angular/common';
import {MainContentComponent} from './components/main-content/main-content.component';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {CustomHttpInterceptor} from './interceptors/custom-http.interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {popperVariation, provideTippyConfig, TippyDirective, tooltipVariation} from '@ngneat/helipopper';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {TopPlaylistsComponent} from './components/top-playlists/top-playlists.component';
import {ToastComponent} from './components/toast/toast.component';
import {AdminPanelComponent} from './components/admin-panel/admin-panel.component';
import {PipeModule} from './pipes/pipe.module';
import {DirectiveModule} from './directives/directive.module';
import {SharedModule} from './shared/shared.module';
import {NavBarModule} from './components/nav-bar/nav-bar.module';
import {PlaylistComponent} from './components/playlist/playlist.component';
import {ImageLoaderDirective} from './directives/image-loader.directive';
import {ProfileModule} from './components/profile/profile.module';

@NgModule({
  declarations: [
    AppComponent,
    MainContentComponent,
    TopPlaylistsComponent,
    ToastComponent,
    AdminPanelComponent,
    PlaylistComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgOptimizedImage,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    ScrollingModule,
    TippyDirective,
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    SharedModule,
    PipeModule,
    DirectiveModule,
    NavBarModule,
    ProfileModule,
    ImageLoaderDirective
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    // {provide: LocationStrategy, useClass: CustomLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true},
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: {
          ...tooltipVariation,
          animation: 'perspective-extreme'
        },
        popper: {
          ...popperVariation,
          animation: 'perspective-extreme'
        },
        menu: {
          ...popperVariation,
          appendTo: 'parent',
          arrow: false,
          offset: [0, 0]
        },
        popperBorder: {
          ...popperVariation,
          theme: 'light-border'
        }
      }
    }),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
