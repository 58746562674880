import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, forkJoin, map, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {SpotifyBaseApiService} from './spotify-base-api.service';
import {PlaylistCreationResponse, TrackSearchResponse} from '../../interfaces/spotify';
import {SpotifyTokensService} from './spotify-tokens.service';
import {PlaylistReturnObject, SongsNotFound} from '../../interfaces/playlist-return-object';

const SPOTIFY_SEARCH_URL = 'https://api.spotify.com/v1/search';
const SPOTIFY_PLAYLIST_URL = (userId: string) => `https://api.spotify.com/v1/users/${userId}/playlists`;
const SPOTIFY_ADD_TRACKS_URL = (playlistId: string) => `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;
const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

@Injectable({
  providedIn: 'root'
})
export class SpotifyPlaylistService extends SpotifyBaseApiService {
  constructor(http: HttpClient, spotifyTokensService: SpotifyTokensService) {
    super(http, spotifyTokensService);
  }

  createSpotifyPlaylist(songs: {
    artist: string,
    title: string
  }[], playlistName: string, playlistId?: string): Observable<PlaylistReturnObject> {
    return this.refreshAccessToken().pipe(
      switchMap(accessToken => this.searchForTracks(songs, accessToken)),
      switchMap(trackData => {
        const trackIds = trackData.map(data => data.id).filter(id => id);
        const songsNotFound: SongsNotFound[] = trackData.filter(data => !data.id).map(data => data.song);

        if (playlistId && this.isPlaylistCached(playlistId)) {
          return this.updatePlaylistTracks(playlistId, trackIds).pipe(
            map(response => ({...response, songsNotFound: songsNotFound}))
          );
        } else {
          return this.createPlaylistAndAddTracks(trackIds, playlistName).pipe(
            map(response => ({...response, songsNotFound: songsNotFound}))
          );
        }
      }),
      catchError(error => this.handleError(error))
    );
  }

  private isPlaylistCached(playlistId: string): boolean {
    const cachedPlaylists = JSON.parse(localStorage.getItem('spotifyPlaylists') || '[]');
    return cachedPlaylists.includes(playlistId);
  }

  private addTracksToPlaylist(playlistId: string, trackIds: string[], accessToken: string): Observable<void> {
    const url = SPOTIFY_ADD_TRACKS_URL(playlistId);
    const body = {uris: trackIds.filter(id => id).map(id => `spotify:track:${id}`)};
    return this.http.post<void>(url, body, {headers: this.getBaseHeaders(accessToken)});
  }

  private clearPlaylistTracks(playlistId: string, accessToken: string): Observable<void> {
    const url = SPOTIFY_ADD_TRACKS_URL(playlistId);
    return this.http.get<{
      items: { track: { uri: string } }[]
    }>(url, {headers: this.getBaseHeaders(accessToken)}).pipe(
      switchMap(playlistData => {
        const trackUris = playlistData.items.map(item => item.track.uri);
        const body = {
          tracks: trackUris.map(uri => ({uri}))
        };
        return this.http.delete<void>(url, {headers: this.getBaseHeaders(accessToken), body: body});
      })
    );
  }

  private updatePlaylistTracks(playlistId: string, trackIds: string[]): Observable<PlaylistReturnObject> {
    return this.refreshAccessToken().pipe(
      switchMap(accessToken => {
        // First, clear the existing tracks from the playlist
        return this.clearPlaylistTracks(playlistId, accessToken).pipe(
          // Then, add the new tracks to the playlist
          switchMap(() => this.addTracksToPlaylist(playlistId, trackIds, accessToken)),
          // Return the playlist link
          map(() => ({link: `https://open.spotify.com/playlist/${playlistId}`, playlistId: playlistId})),
          catchError(error => this.handleError(error))
        );
      })
    );
  }

  private getTracksCache(): { [key: string]: { id: string, timestamp: number } } {
    return JSON.parse(localStorage.getItem('spotifyTracks') || '{}');
  }

  private setTracksCache(tracks: { [key: string]: { id: string, timestamp: number } }): void {
    localStorage.setItem('spotifyTracks', JSON.stringify(tracks));
  }

  private getTrackQueue(): string[] {
    return JSON.parse(localStorage.getItem('trackQueue') || '[]');
  }

  private setTrackQueue(queue: string[]): void {
    localStorage.setItem('trackQueue', JSON.stringify(queue));
  }

  private cleanupExpiredTracks(): void {
    const tracks = this.getTracksCache();
    const queue = this.getTrackQueue();
    const now = Date.now();

    while (queue.length && tracks[queue[0]] && now - tracks[queue[0]].timestamp > THREE_DAYS_IN_MS) {
      const expiredTrack = queue.shift();
      if (expiredTrack && tracks[expiredTrack]) {
        delete tracks[expiredTrack];
      }
    }

    this.setTracksCache(tracks);
    this.setTrackQueue(queue);
  }

  private cleanString(input: string): string {
    // Remove text inside parentheses
    let cleaned = input.replace(/\(.*?\)/g, '').trim();
    // Remove special characters
    cleaned = cleaned.replace(/[^a-zA-Z0-9\s]/g, '');
    return cleaned;
  }

  private searchForTracks(songs: { artist: string, title: string }[], accessToken: string): Observable<{
    id: string,
    song: { artist: string, title: string }
  }[]> {
    const trackObservables = songs.map(song => {
      const cacheKey = `${song.artist} - ${song.title}`;
      const cachedTrack = this.getTracksCache()[cacheKey];

      if (cachedTrack) {
        return of({id: cachedTrack.id, song});
      }

      const searchWithCleanedStrings = () => {
        const cleanedArtist = this.cleanString(song.artist);
        const cleanedTitle = this.cleanString(song.title);
        return this.http.get<TrackSearchResponse>(
          `${SPOTIFY_SEARCH_URL}?q=artist:${encodeURIComponent(cleanedArtist)}%20track:${encodeURIComponent(cleanedTitle)}&type=track&limit=10`,
          {headers: this.getBaseHeaders(accessToken)})
          .pipe(
            map(data => {
              if (data.tracks && data.tracks.items.length) {
                const trackId = data.tracks.items[0].id;
                return {id: trackId, song};
              } else {
                // Return the song with an empty ID if not found
                return {id: '', song};
              }
            })
          );
      };

      return this.http.get<TrackSearchResponse>(
        `${SPOTIFY_SEARCH_URL}?q=artist:${encodeURIComponent(song.artist)}%20track:${encodeURIComponent(song.title)}&type=track&limit=10`,
        {headers: this.getBaseHeaders(accessToken)})
        .pipe(
          switchMap(data => {
            if (data.tracks && data.tracks.items.length) {
              const trackId = data.tracks.items[0].id;
              return of({id: trackId, song});
            } else {
              // Retry with cleaned strings if no tracks are found
              return searchWithCleanedStrings();
            }
          })
        );
    });

    return forkJoin(trackObservables);
  }

  private cachePlaylist(playlistId: string): void {
    const playlists = JSON.parse(localStorage.getItem('spotifyPlaylists') || '[]');
    if (!playlists.includes(playlistId)) {
      playlists.push(playlistId);
      localStorage.setItem('spotifyPlaylists', JSON.stringify(playlists));
    }
  }

  private createPlaylistAndAddTracks(trackIds: string[], playlistName: string): Observable<PlaylistReturnObject> {
    return this.refreshAccessToken().pipe(
      switchMap(accessToken => {
        return this.http.post<PlaylistCreationResponse>(
          SPOTIFY_PLAYLIST_URL(this.spotifyTokensService.getUserId()),
          {name: playlistName, public: true},
          {headers: this.getBaseHeaders(accessToken)}
        ).pipe(
          switchMap(playlist => {
            this.cachePlaylist(playlist.id);
            return this.http.post(
              SPOTIFY_ADD_TRACKS_URL(playlist.id),
              {uris: trackIds.filter(id => id).map(id => `spotify:track:${id}`)},
              {headers: this.getBaseHeaders(accessToken)}
            ).pipe(
              map(() => ({link: `https://open.spotify.com/playlist/${playlist.id}`, playlistId: playlist.id})),  // <-- Return the playlist ID here
              catchError(error => this.handleError(error))
            );
          })
        );
      })
    );
  }
}
