import {Injectable} from '@angular/core';
import {YoutubeBaseApiService} from './youtube-base-api.service';
import {concatMap, switchMap, toArray} from 'rxjs/operators';
import {catchError, from, map, mergeMap, Observable, of, throwError} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {PlaylistReturnObject} from '../../interfaces/playlist-return-object';

@Injectable({
  providedIn: 'root'
})
export class YoutubePlaylistService extends YoutubeBaseApiService {
  private playlistItemsUrl = 'https://www.googleapis.com/youtube/v3/playlistItems';
  private playlistApiUrl = 'https://www.googleapis.com/youtube/v3/playlists';
  private playlistItemsApiUrl = 'https://www.googleapis.com/youtube/v3/playlistItems';

  createYoutubePlaylist(youtubeIds: string[], playlistName: string, playlistId?: string): Observable<PlaylistReturnObject> {
    return this.refreshAccessToken().pipe(
      switchMap(() => {
        if (playlistId) {
          return this.updatePlaylistTracks(playlistId, youtubeIds);
        } else {
          return this.createPlaylistAndAddTracks(playlistName, youtubeIds);
        }
      }),
      catchError(error => this.handleError(error))
    );
  }

  private createPlaylistAndAddTracks(playlistName: string, youtubeIds: string[]): Observable<PlaylistReturnObject> {
    return this.createPlaylist(playlistName).pipe(
      switchMap(playlistId =>
        this.addTracksToPlaylist(playlistId, youtubeIds).pipe(
          map(() => ({
            link: this.generatePlaylistLink(playlistId),
            playlistId: playlistId,
          }))
        )
      ),
      catchError(error => {
        console.error('Error creating playlist and adding tracks:', error);
        return throwError(() => error);
      })
    );
  }

  private generatePlaylistLink(playlistId: string): string {
    return `https://www.youtube.com/playlist?list=${playlistId}`;
  }

  private createPlaylist(playlistName: string): Observable<string> {
    const body = {
      snippet: {
        title: playlistName,
        description: 'Playlist created via My YouTube App', // You can customize this
        privacyStatus: 'public' // or 'private', 'unlisted'
      }
    };

    return this.http.post<{ id: string }>(this.playlistApiUrl, body, {
      params: {
        part: 'snippet',
        key: environment.youtubeApiKey
      }
    }).pipe(
      map(response => response.id),
      catchError(error => {
        console.error('Error creating playlist:', error);
        return throwError(() => error);
      })
    );
  }

  private updatePlaylistTracks(playlistId: string, youtubeIds: string[]): Observable<PlaylistReturnObject> {
    return this.deletePlaylistTracks(playlistId).pipe(
      switchMap(() => this.addTracksToPlaylist(playlistId, youtubeIds)),
      map(() => ({
        link: this.generatePlaylistLink(playlistId),
        playlistId: playlistId
      }))
    );
  }

  private deletePlaylistTracks(playlistId: string): Observable<any> {
    return this.getPlaylistItems(playlistId).pipe(
      concatMap(items => from(items)),
      concatMap(item => this.deletePlaylistItem(item.id)),
      toArray() // Collects all responses
    );
  }

  private getPlaylistItems(playlistId: string): Observable<any[]> {
    const params = {
      part: 'snippet',
      maxResults: '500', // Adjust as needed
      playlistId: playlistId,
      key: environment.youtubeApiKey // Replace with your YouTube API key
    };

    return this.http.get<{ items: any[] }>(this.playlistItemsUrl, {params})
      .pipe(
        map(response => response.items || []),
        catchError(error => {
          console.error('Error fetching playlist items:', error);
          return of([]); // Return an empty array in case of an error
        })
      );
  }

  private deletePlaylistItem(playlistItemId: string): Observable<any> {
    const params = {
      id: playlistItemId,
      key: environment.youtubeApiKey
    };

    return this.http.delete(this.playlistItemsUrl, {params});
  }

  private addTracksToPlaylist(playlistId: string, youtubeIds: string[]): Observable<any> {
    return from(youtubeIds).pipe(
      mergeMap(youtubeId =>
          this.addTrackToPlaylist(playlistId, youtubeId),
        10 // This limits the number of concurrent requests
      ),
      // Use forkJoin to wait for all additions to complete
      toArray()
    );
  }

  private addTrackToPlaylist(playlistId: string, youtubeId: string): Observable<any> {
    const body = {
      snippet: {
        playlistId: playlistId,
        resourceId: {
          kind: 'youtube#video',
          videoId: youtubeId
        }
      }
    };

    return this.http.post(this.playlistItemsUrl, body, {
      params: {
        part: 'snippet',
        key: environment.youtubeApiKey
      }
    });
  }
}
